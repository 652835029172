/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddCreditInput = {|
  applyBrandIds?: ?$ReadOnlyArray<?string>,
  applyCreditMode?: ?number,
  creditFromProducts?: ?$ReadOnlyArray<?InputCreditFromProducts>,
  endDate: string,
  excludeBrandIds?: ?$ReadOnlyArray<?string>,
  excludeNonStock?: ?boolean,
  excludePriceDrop?: ?boolean,
  excludeProducts?: ?$ReadOnlyArray<?string>,
  excludeProductsSpend?: ?$ReadOnlyArray<?string>,
  excludeSpecialOrder?: ?boolean,
  expiry?: ?number,
  fromBrandIds?: ?$ReadOnlyArray<?string>,
  includeNonStockBrands?: ?$ReadOnlyArray<?string>,
  includeProducts?: ?$ReadOnlyArray<?string>,
  minSpent?: ?number,
  name: string,
  percentage?: ?number,
  promoItems?: ?$ReadOnlyArray<?string>,
  startDate: string,
  status: boolean,
  stealthMode?: ?boolean,
  tiers?: ?$ReadOnlyArray<?InputCreditTier>,
|};
export type InputCreditFromProducts = {|
  creditAmount: number,
  id?: ?string,
  productId: string,
|};
export type InputCreditTier = {|
  creditAmount: number,
  id?: ?string,
  price: number,
|};
export type AddCreditMutationVariables = {|
  input: AddCreditInput
|};
export type AddCreditMutationResponse = {|
  +addCredit: ?{|
    +creditEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +fromBrandIds: ?$ReadOnlyArray<?string>,
        +name: ?string,
        +tiers: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +price: ?number,
              +creditAmount: ?number,
            |}
          |}>
        |},
        +promoItems: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +status: ?string,
            |}
          |}>
        |},
        +creditFromProducts: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +product: ?{|
                +id: string,
                +name: ?string,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                |},
              |},
              +creditAmount: ?number,
            |}
          |}>
        |},
        +percentage: ?number,
        +minSpent: ?number,
        +applyBrandIds: ?$ReadOnlyArray<?string>,
        +applyCreditMode: ?number,
        +excludeBrandIds: ?$ReadOnlyArray<?string>,
        +includeProducts: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
        +excludeProducts: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
        +excludeProductsSpend: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
        +startDate: ?string,
        +endDate: ?string,
        +expiry: ?number,
        +stealthMode: ?boolean,
        +excludeNonStock: ?boolean,
        +includeNonStockBrands: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
        |}>,
        +excludePriceDrop: ?boolean,
        +status: ?boolean,
      |},
    |}
  |}
|};
export type AddCreditMutation = {|
  variables: AddCreditMutationVariables,
  response: AddCreditMutationResponse,
|};
*/


/*
mutation AddCreditMutation(
  $input: AddCreditInput!
) {
  addCredit(input: $input) {
    creditEdge {
      cursor
      node {
        id
        fromBrandIds
        name
        tiers(first: 999) {
          edges {
            node {
              id
              price
              creditAmount
            }
          }
        }
        promoItems(first: 999) {
          edges {
            node {
              id
              name
              status
            }
          }
        }
        creditFromProducts(first: 999) {
          edges {
            node {
              id
              product {
                id
                name
                mainImage {
                  id
                  url
                }
              }
              creditAmount
            }
          }
        }
        percentage
        minSpent
        applyBrandIds
        applyCreditMode
        excludeBrandIds
        includeProducts(first: 9999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        excludeProducts(first: 9999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        excludeProductsSpend(first: 9999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        startDate
        endDate
        expiry
        stealthMode
        excludeNonStock
        includeNonStockBrands {
          id
          name
        }
        excludePriceDrop
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creditAmount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductImage",
    "kind": "LinkedField",
    "name": "mainImage",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  }
],
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddCreditPayload",
    "kind": "LinkedField",
    "name": "addCredit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CreditEdge",
        "kind": "LinkedField",
        "name": "creditEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Credit",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fromBrandIds",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "CreditTierConnection",
                "kind": "LinkedField",
                "name": "tiers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreditTierEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreditTier",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "price",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "tiers(first:999)"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "promoItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "promoItems(first:999)"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "CreditFromProductsConnection",
                "kind": "LinkedField",
                "name": "creditFromProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreditFromProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreditFromProducts",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "creditFromProducts(first:999)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "percentage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minSpent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "applyBrandIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "applyCreditMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "excludeBrandIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "includeProducts",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": "includeProducts(first:9999)"
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "excludeProducts",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": "excludeProducts(first:9999)"
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "excludeProductsSpend",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": "excludeProductsSpend(first:9999)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expiry",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stealthMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "excludeNonStock",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Brand",
                "kind": "LinkedField",
                "name": "includeNonStockBrands",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "excludePriceDrop",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCreditMutation",
    "selections": (v9/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddCreditMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "352c9f3185025161486e3384fb1eedd6",
    "id": null,
    "metadata": {},
    "name": "AddCreditMutation",
    "operationKind": "mutation",
    "text": "mutation AddCreditMutation(\n  $input: AddCreditInput!\n) {\n  addCredit(input: $input) {\n    creditEdge {\n      cursor\n      node {\n        id\n        fromBrandIds\n        name\n        tiers(first: 999) {\n          edges {\n            node {\n              id\n              price\n              creditAmount\n            }\n          }\n        }\n        promoItems(first: 999) {\n          edges {\n            node {\n              id\n              name\n              status\n            }\n          }\n        }\n        creditFromProducts(first: 999) {\n          edges {\n            node {\n              id\n              product {\n                id\n                name\n                mainImage {\n                  id\n                  url\n                }\n              }\n              creditAmount\n            }\n          }\n        }\n        percentage\n        minSpent\n        applyBrandIds\n        applyCreditMode\n        excludeBrandIds\n        includeProducts(first: 9999) {\n          edges {\n            node {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n        excludeProducts(first: 9999) {\n          edges {\n            node {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n        excludeProductsSpend(first: 9999) {\n          edges {\n            node {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n        startDate\n        endDate\n        expiry\n        stealthMode\n        excludeNonStock\n        includeNonStockBrands {\n          id\n          name\n        }\n        excludePriceDrop\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '021d5c98887a5877e5b7468c11795a75';

module.exports = node;
