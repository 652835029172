import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Alert, Divider, Select, Spin, Switch } from 'antd';
import { DatePicker } from '~/components/form';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { getFrontEndUrl } from '~/helper';

const { RangePicker } = DatePicker;
const { Option } = Select;

const colours = {
  qty: "#8884d8",
  row_total: "#82ca9d",
}

const entityName = 'By Subcategory Report';

const DATASOURCE = {
  edges: [
    {
      node: {
        id: 'online',
        name: 'Online'
      },
    },
    {
      node: {
        id: 'instore',
        name: 'In Store (Top 300)',
      }
    },
    {
      node: {
        id: 'online_instore',
        name: 'Online + In Store (Top 300)',
      },
    },
  ]
};

class BySubcategoryReport extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      barChartReport: PropTypes.arrayOf(PropTypes.object).isRequired,
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
      subcategories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      refetch: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const { brands, categories, subcategories } = props.viewer;

    this.inputs = {
      brand: {
        label: "Brand",
        list: brands,
        onChange: this.onBrandChange,
      },
      category: {
        label: "Category",
        list: categories,
        onChange: this.onCategoryChange,
      },
      dataSource: {
        label: "Data Source",
        list: DATASOURCE,
        onChange: this.onDataSourceChange,
      },
      subcategory: {
        label: "Subcategory",
        list: subcategories,
        onChange: this.onSubcategoryChange,
      },
    };

    this.state = {
      brandId: undefined,
      categoryId: undefined,
      dateRange: null,
      dataSource: DATASOURCE.edges[0].node.id,
      subcategoryId: undefined,
      yDataKey: 'model',
      byBrand: false,
      loading: false,
      orderBy: 'qty',
    };
  }

  onDateChange = (dateRange) => {
    this.setState({dateRange}, () => {
      this.refetch();
    });
  }

  onBrandChange = (brandId) => {
    this.setState({brandId}, () => {
      this.refetch();
    });
  }

  onCategoryChange = (categoryId) => {
    this.setState({categoryId}, () => {
      this.refetch();
    });
  }

  onDataSourceChange = (dataSource) => {
    this.setState({dataSource}, () => {
      this.refetch();
    });
  }

  onSubcategoryChange = (subcategoryId) => {
    this.setState({subcategoryId}, () => {
      this.refetch();
    });
  }

  onByBrandChange = (byBrand) => {
    const yDataKey = byBrand ? "brand" : "model";

    this.setState({byBrand, yDataKey}, () => {
      this.refetch();
    });
  }

  onOrderByChange = (orderBy) => {
    this.setState({orderBy}, () => {
      this.refetch();
    });
  }

  refetch = () => {
    const { brandId, byBrand, categoryId, dateRange, dataSource, subcategoryId } = this.state;

    if (!dateRange) {
      return;
    }

    const dateRangeFilter = [moment(dateRange[0]).startOf('day').utc().format(), moment(dateRange[1]).endOf('day').utc().format()].join(",");

    const filterBy = [
      {
        field: "inserted_at",
        filter: dateRangeFilter,
        filterType: "text",
        type: "inRange",
      }
    ];

    if (subcategoryId) {
      filterBy.push({
        field: "subcategory_id",
        filter: subcategoryId,
        filterType: "text",
        type: "equals",
      })
    }

    if (categoryId) {
      filterBy.push({
        field: "category_id",
        filter: categoryId,
        filterType: "text",
        type: "equals",
      })
    }

    if (brandId) {
      filterBy.push({
        field: "brand_id",
        filter: brandId,
        filterType: "text",
        type: "equals",
      })
    }

    const orderBy = {
      field: this.state.orderBy,
      direction: 'desc'
    };

    const refetchVariables = { filterBy, orderBy, dataSource };

    if (byBrand) {
      refetchVariables.type = "by_brand";
    }

    this.setState({ loading: true });
    this.props.relay.refetch(
      refetchVariables,
      null,
      () => {
        this.setState({ loading: false });
      }
    );
  }

  renderToolTip = ({payload, label}) => {
    if (typeof label === 'undefined' || payload.length !== 1) {
      return null;
    }

    return (
      <div
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(204, 204, 204)',
          whiteSpace: 'nowrap',
        }}
      >
        <div>{label}</div>
        <div style={{color: colours.qty}}>Qty: {payload[0].payload.qty}</div>
        <div style={{color: colours.row_total}}>Revenue: ${payload[0].payload.row_total.toFixed(2)}</div>
        <div>
          <img width="50" src={`/assets/images/products/${payload[0].payload.image}`} alt="" />
        </div>
      </div>
    );
  }

  renderTotal = (items) => {
    const total = items.reduce((acc, item) => acc + item.row_total, 0)
    return `Total Revenue: $${total.toFixed(2)}`;
  }

  renderSelect = (input, val) => (
    <Select
      allowClear
      style={{width: '50%', display: 'block', marginTop: '10px'}}
      placeholder={input.label}
      optionFilterProp="children"
      onChange={input.onChange}
      filterOption={(i, option) => option.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0}
      value={val}
      showSearch
    >
      {
        input.list.edges.map((edge) => {
          const e = edge.node;
          return <Option key={e.id} value={e.id}>{e.name}</Option>;
        })
      }
    </Select>
  )

  render() {
    const barChartReport = get(this.props.viewer, 'barChartReport', []);
    const { brandId, byBrand, categoryId, dateRange, dataSource, loading, orderBy, subcategoryId, yDataKey } = this.state;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        <h1>{entityName}</h1>

        <Alert message="Data from Aden is not 100% accurate, please keep this in mind. There are products missing/unmatched between the website and Aden" type="warning" />

        <RangePicker value={dateRange} onChange={this.onDateChange} />

        {this.renderSelect(this.inputs.dataSource, dataSource)}

        {this.renderSelect(this.inputs.category, categoryId)}

        {this.renderSelect(this.inputs.subcategory, subcategoryId)}

        {this.renderSelect(this.inputs.brand, brandId)}

        <div>
          Sort By:
          <Select
            placeholder="Sort By"
            defaultValue={orderBy}
            onChange={this.onOrderByChange}
            style={{ width: '100px', display: 'inline-block', marginTop: '10px', marginLeft: '5px'}}
          >
            <Option value="qty">Qty</Option>
            <Option value="row_total">Revenue</Option>
          </Select>
        </div>

        <div>
          By Brand: <Switch defaultValue={byBrand} onChange={this.onByBrandChange}  />
        </div>

        <Divider />

        {loading && (<Spin size="large" />)}

        {this.renderTotal(barChartReport)}

        {(barChartReport.length > 0) && (
        <ResponsiveContainer height={barChartReport.length < 50 ? 680 : barChartReport.length * 20}>
          <BarChart
            data={barChartReport}
            layout="vertical"
            onClick={(chart) => {
              const urlSlug = get(chart, 'activePayload[0].payload.url_slug');
              if (urlSlug) {
                window.open(`${getFrontEndUrl()}/product/${urlSlug}`, '_blank');
              }
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis type="category" dataKey={yDataKey} width={120} interval={0} />
            <Tooltip content={this.renderToolTip} />
            <Legend verticalAlign="top" />
            <Bar dataKey={orderBy} fill={colours[orderBy]} />
          </BarChart>
        </ResponsiveContainer>
        )}
      </div>
    );
  }
}

export default createRefetchContainer(
  BySubcategoryReport,
  {
    viewer: graphql`
      fragment BySubcategoryReport_viewer on Admin {
        barChartReport(filterBy: $filterBy, orderBy: $orderBy, type: $type, dataSource: $dataSource)
        brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
          edges {
            node {
              id
              name
            }
          }
        }
        categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
          edges {
            node {
              id
              name
            }
          }
        }
        subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  graphql`
    query BySubcategoryReportRefetchQuery($filterBy: [FilterBy], $orderBy: OrderBy, $type: String, $dataSource: String) {
      viewer {
        barChartReport(filterBy: $filterBy, orderBy: $orderBy, type: $type, dataSource: $dataSource)
      }
    }
  `,
);
