import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { AddAdminUserMutation } from './mutations';
import { fromGlobalId } from '../../helper';
import UserForm from './UserForm';

class UserNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      adminRoles: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;
    values.roles = values.roles.map(p => p.key)
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        window.location.href = `/user/${fromGlobalId(resp.addAdminUser.adminUserEdge.node.id).id}`;
      },
    };

    AddAdminUserMutation.commit(mutation);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New User" />

        <h1>New User</h1>
        <UserForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(UserNew, {
  viewer: graphql`
    fragment UserNew_viewer on Admin {
      id
      adminRoles(first: 999) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
