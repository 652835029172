/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserNew_viewer$ref = any;
export type routes_UserNew_QueryVariables = {||};
export type routes_UserNew_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: UserNew_viewer$ref
  |}
|};
export type routes_UserNew_Query = {|
  variables: routes_UserNew_QueryVariables,
  response: routes_UserNew_QueryResponse,
|};
*/


/*
query routes_UserNew_Query {
  viewer {
    ...UserNew_viewer
    id
  }
}

fragment UserNew_viewer on Admin {
  id
  adminRoles(first: 999) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_UserNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserNew_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_UserNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 999
              }
            ],
            "concreteType": "AdminRoleConnection",
            "kind": "LinkedField",
            "name": "adminRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminRoleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "adminRoles(first:999)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a74a67f015fbfbe7c156e7643103afbb",
    "id": null,
    "metadata": {},
    "name": "routes_UserNew_Query",
    "operationKind": "query",
    "text": "query routes_UserNew_Query {\n  viewer {\n    ...UserNew_viewer\n    id\n  }\n}\n\nfragment UserNew_viewer on Admin {\n  id\n  adminRoles(first: 999) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d1c8d3b21a16ce31bd8f79c880ad90d';

module.exports = node;
