import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';
import moment from 'moment-timezone';

const routes = (
  <Route
    path="/report"
  >
    <Route
      path="/sales-report"
      getComponent={() => (
        import(/* webpackChunkName: "SalesReport" */'./SalesReport').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_SalesReport_Query ($orderBy: OrderBy, $filterBy: [FilterBy]) {
          viewer {
            ...SalesReport_viewer
          }
        }
      `}
      prepareVariables={(params, router) => {
        let startDate = moment().startOf('day').utc().format();
        let endDate = moment().endOf('day').utc().format();

        const rangeFilter = {
          field: "inserted_at",
          filter: [startDate, endDate].join(","),
          filterType: "text",
          type: "inRange",
        };

        const locationFilter = {
          field: "shipments.lines.location_code",
          filter: "[]",
          filterType: "text",
          type: "in",
        }

        if (router) {
          const { location: { query } } = router;

          const { range, loc } = query;
          if (range) {
            const dates = range.split(",");
            if (dates.length === 2) {
              startDate = moment(dates[0]).startOf('day').local().format();
              endDate = moment(dates[1]).endOf('day').local().format();
              rangeFilter.filter = [startDate, endDate].join(",");
            }
          }

          if (loc) {
            locationFilter.filter = loc;
          }
        }

        return {
          count: 2147483647,
          filterBy: [rangeFilter, locationFilter]
        };
      }}
    />

    <Route
      path="/sales-trend"
      getComponent={() => (
        import(/* webpackChunkName: "SalesTrend" */'./SalesTrend').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_SalesTrend_Query ($filterBy: [FilterBy]) {
          viewer {
            ...SalesTrend_viewer
          }
        }
      `}
      prepareVariables={() => ({
        count: 2147483647,
        filterBy: {
          field: "inserted_at",
          filter: [moment().startOf('day').subtract(7,'d').utc().format(), moment().endOf('day').utc().format()].join(","),
          filterType: "text",
          type: "inRange",
        }
      })}
    />

    <Route
      path="/by-subcategory"
      getComponent={() => (
        import(/* webpackChunkName: "BySubcategoryReport" */'./BySubcategoryReport').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_BySubcategoryReport_Query($filterBy: [FilterBy], $orderBy: OrderBy, $type: String, $dataSource: String) {
          viewer {
            ...BySubcategoryReport_viewer
          }
        }
      `}
    />
  </Route>
);

export default routes;
