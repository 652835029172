import moment from 'moment-timezone';
import { message } from 'antd';

let DEFAULT_WEBSITE = 'AU';

const getWebsite = function getWebsite() {
  const website = sessionStorage.getItem('website');
  if (website) {
    return website;
  }

  return DEFAULT_WEBSITE;
}

const setWebsite = function setWebsite(website = DEFAULT_WEBSITE) {
  if (['AU', 'NZ'].includes(website)) {
    sessionStorage.setItem('website', website);
    DEFAULT_WEBSITE = website;
  } else {
    message.error("Invalid Website is set")
  }
}

const base64 = function base64(i) {
  return Buffer.from(i, 'utf8').toString('base64');
  }

const unbase64 = function unbase64(i) {
  return Buffer.from(i, 'base64').toString('utf8');
}

const getNodeIds = (rows) => rows.map(edge => edge.node.id);

/**
 ** Takes the "global ID" created by toGlobalID, and returns the type name and ID
 ** used to create it.
 * */
const fromGlobalId = function fromGlobalId(globalId) {
  const unbasedGlobalId = unbase64(globalId);
  const delimiterPos = unbasedGlobalId.indexOf(':');
  return {
    type: unbasedGlobalId.substring(0, delimiterPos),
    id: unbasedGlobalId.substring(delimiterPos + 1)
  };
};

/**
 ** Takes a type name and an ID specific to that type name, and returns a
 ** "global ID" that is unique among all types.
 * */
const toGlobalId = function toGlobalId(type, id) {
  return base64([ type, id ].join(':'));
};

const getTLD = function getTLD() {
  if (window.location.host.includes('co.nz')) {
    return '.co.nz';
  }

  return '.com.au';
}

const getFrontEndUrl = function getFrontEndUrl(tld = getTLD()) {
  let url = `https://sydneytools${tld}`;

  if (process.env.NODE_ENV === 'development') {
    url = `http://relay-sydneytools${tld}`;
  } else if (process.env.NODE_ENV === 'staging') {
    url = `https://staging.sydneytools${tld}`;
  }
  return url;
}

const getLink = (type, country, id) => {
  const domain = window.location.origin.split('.').slice(0, -2).join('.')  ;

  let url = "";
  const urlPath = `/${type}/${id}`;

  if (country === 'AU') {
    url = domain.concat(`.com.au${urlPath}`);
  } else if (country === 'NZ') {
    url = domain.concat(`.co.nz${urlPath}`);
  } else {
    console.error(`Unable to find corresponding country: ${country}.`)
  }

  return url;
}

const validateEmail = function validateEmail(email) {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email.trim());
}

const checkEmails = function checkEmails(rule, emails) {
  if ( emails && emails.length > 0) {
    const check = emails.every(email => validateEmail(email) === true);

    if (!check) {
      return Promise.reject(new Error('invalid email'));
    }
  }

  return Promise.resolve();
}

const genCsv = (list, csvHeaders, getFilename) => {

  const products = list.map(i => [csvHeaders.reduce((acc, h) => {
    const val = i[h];

    if (Array.isArray(val)) {
      acc.push(val.toString());
    } else {
      acc.push(val);
    }
    return acc;
  }, [])]);

  const data = [csvHeaders].concat(products).map(e => e.flat().map(i => {
      if (i !== null && i !== undefined) {
        const escape = String(i).split('"').join('\"\"'); // eslint-disable-line no-useless-escape
        return `"${escape}"`;
      }
      return `""`;
    }
  ).join(",")).join("\n");

  const csv = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;

  const link = document.createElement("a");
  link.setAttribute("href", csv);

  const timeNow = moment().format('YYYY-MM-DD-HH:mm:ss');
  link.setAttribute("download", `${getFilename(timeNow)}.csv`);

  document.body.appendChild(link);

  link.click();
};

module.exports = {
  getWebsite,
  setWebsite,
  fromGlobalId,
  getNodeIds,
  toGlobalId,
  getTLD,
  getFrontEndUrl,
  getLink,
  checkEmails,
  genCsv,
};
