import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Form, Input, InputNumber, Radio, Select, Switch, Table, Tabs } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { DatePicker, FormBase, formItemLayout, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';
import { ShowLabel } from '~/components/promotion/label';
import { fromGlobalId } from '~/helper';

const { Item: FormItem } = Form;
const { Option } = Select;
const { Group: RadioGroup } = Radio;
const { TabPane } = Tabs;

class CreditForm extends FormBase {
  static propTypes = {
    credit: PropTypes.shape({
      id: PropTypes.string,
      fromBrandIds: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      tiers: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      excludeProducts: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      excludeProductsSpend: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      includeProducts: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.bool,
    }),
    viewer: PropTypes.shape({
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    credit: {},
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      defaultMode: this.getDefaultMode(props.credit),
    };
  }

  getDefaultMode = (credit) => {
    let defaultMode = 'price';

    if (get(credit.creditFromProducts,'edges', []).length > 0) {
      defaultMode = 'product';
    }
    else if (credit.percentage > 0) {
      defaultMode = 'percentage';
    }

    return defaultMode;
  }

  getInitProducts = (defaultMode, tiers, creditFromProducts) => {
    if (defaultMode === 'price') {
      return tiers.length ? tiers.slice(0) : [{ node: {} }];
    }else if (creditFromProducts.length ) {
      return creditFromProducts.slice(0);
    }

    return [{ node: {} }];

  }

  label = (form) => {
    if (form.getFieldValue('applyCreditMode') === 0) {
      return "Credits CANNOT be spent on"
    }

    return "Credits CAN be spent on"
  }

  renderOrders = orders => { // eslint-disable-line react/display-name
    if (orders) {
      return (
        <Table
          dataSource={orders}
          rowKey={record => record.node.id}
          columns={[
            {
              title: 'View',
              dataIndex: 'node',
              render: node => <a href={`/sales/order/${fromGlobalId(node.orderId).id}`} rel="noopener noreferrer" target="_blank">{node.orderName}</a>, // eslint-disable-line react/display-name
            },
            {
              title: 'Credit Amount',
              dataIndex: ['node', 'creditAmount'],
              render: value => `$${value}`,
            },
            {
              title: 'Credit Remaining',
              dataIndex: ['node', 'creditRemaining'],
              render: value => `$${value}`,
            },
            {
              title: 'Expiry',
              dataIndex: ['node', 'expiry'],
              render: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
            },
          ]}
        />
      );
    }

    return null;
  }

  render() {
    const { match, viewer } = this.props;
    const credit = get(this.props, 'credit', {});
    const brands = get(viewer, 'brands.edges', []);
    const tiers = get(credit, 'tiers.edges', []);
    const creditFromProducts = get(credit, 'creditFromProducts.edges', []);

    const initProducts = this.getInitProducts(this.state.defaultMode, tiers, creditFromProducts);

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values, { setLoading: this.setLoading }); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            {this.renderSaveButton()}
            {credit.id && (
            <React.Fragment>
              <a href={`/api/promotion/download?id=${credit.id}`} target="_blank" rel="noopener noreferrer">Download</a>
            </React.Fragment>
            )}
          </div>
        </Affix>

        <h2>DO NOT RE-CYCLE PROMOTION</h2>

        <p>
          Products assigned to <b>gift-card</b> subcategory will be excluded both from earning and spending.
        </p>

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            {credit.id && (
              <FormItem
                name="id"
                initialValue={credit.id}
                hidden
              >
                <Input />
              </FormItem>
            )}

            <FormItem
              {...formItemLayout}
              label="Name"
            >
              <FormItem
                name="name"
                rules={[{ required: true, message: 'required' }]}
                initialValue={get(credit, 'name')}
              >
                <Input placeholder="Name" />
              </FormItem>

              <ShowLabel labels={credit.labels} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Earn From Brands"
              name="fromBrandIds"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(credit, 'fromBrandIds')}
            >
              <Select mode="multiple" placeholder="Select Brand" showSearch optionFilterProp="children" >
                <Option key="*" value="*">All Brands</Option>
                {
                  brands.map((edge) => {
                    const brand = edge.node;
                    return <Option key={brand.id} value={brand.id}>{brand.name}</Option>;
                  })
                }
              </Select>
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const fromBrandIds = getFieldValue('fromBrandIds');

                return fromBrandIds && fromBrandIds.includes('*') ? (
                  <FormItem
                    {...formItemLayout}
                    label="Exclude brands"
                    name="excludeBrandIds"
                    initialValue={credit.excludeBrandIds}
                    help={<div>These brands will also be excluded from spending.</div>}
                  >
                    <Select
                      allowClear
                      mode="multiple"
                      optionFilterProp="children"
                      optionLabelProp="children"
                      placeholder="Select Brand"
                    >
                      {brands.map(({ node: brand }) => (
                        <Option key={brand.id} value={brand.id}>{brand.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                ) : null
              }}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Include Only Products"
              extra="Only these products will be included in promotion"
              name="includeProducts"
              rules={[
                { message: 'Cannot use include and exclucde products at the same time',
                  validator: (rule, val) => {
                    const excludeProducts = this.formRef.current.getFieldValue('excludeProducts') || [];
                    if (excludeProducts.length > 0 && val.length > 0) {
                      return Promise.reject(new Error(rule.message));
                    }
                    return Promise.resolve();
                  }
                }
              ]}
              initialValue={get(credit, 'includeProducts.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
            >
              <SelectProduct
                placeholder="Include Products"
                mode="multiple"
                labelInValue
                filterOption={false}
                viewer={viewer}
                importExport={{
                  onImport: (value) => { SelectProduct.updateSelect(this.formRef.current, value, 'includeProducts'); },
                  listToExport: get(credit, 'includeProducts.edges', [])
                }}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Exclude Only Products"
              extra="These products will be excluded from earning credits"
              name="excludeProducts"
              rules={[
                {
                  message: 'Cannot use include and exclucde products at the same time',
                  validator: (rule, val) => {
                    const includeProducts = this.formRef.current.getFieldValue('includeProducts') || [];
                    if (includeProducts.length > 0 && val.length > 0) {
                      return Promise.reject(new Error(rule.message));
                    }
                    return Promise.resolve();
                  }
                }
              ]}
              initialValue={get(credit, 'excludeProducts.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
            >
              <SelectProduct
                placeholder="Exclude Products"
                mode="multiple"
                labelInValue
                filterOption={false}
                viewer={viewer}
                importExport={{
                  onImport: (value) => { SelectProduct.updateSelect(this.formRef.current, value, 'excludeProducts'); },
                  listToExport: get(credit, 'excludeProducts.edges', [])
                }}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Exclude Non Stock Products"
              name="excludeNonStock"
              valuePropName="checked"
              initialValue={get(credit, 'excludeNonStock', true)}
            >
              <Switch />
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => getFieldValue('excludeNonStock') ? (
                <FormItem
                  {...formItemLayout}
                  label="Include Non Stock Brands"
                  name="includeNonStockBrands"
                  initialValue={get(credit, 'includeNonStockBrands', []).map(b => b.id)}
                >
                  <Select placeholder="Select Brand" showSearch optionFilterProp="children" mode="multiple" >
                    {brands.map(({ node: brand }) => (
                      <Option key={brand.id} value={brand.id}>{brand.name}</Option>
                    ))}
                  </Select>
                </FormItem>
              ) : null}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Exclude Price Drop Products"
              name="excludePriceDrop"
              valuePropName="checked"
              initialValue={get(credit, 'excludePriceDrop', true)}
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Mode"
            >
              <RadioGroup onChange={(e) => { this.setState({ defaultMode: e.target.value }); }} value={this.state.defaultMode}>
                <Radio value="price">On Price</Radio>
                <Radio value="product">On Product</Radio>
                <Radio value="percentage">On Percentage</Radio>
              </RadioGroup>
            </FormItem>

            {this.state.defaultMode === 'product' && (
            <FormItem
              {...formItemLayout}
              label="On Products"
            >
              <Form.List
                name="creditFromProducts"
                initialValue={initProducts.map(({ node }) => ({
                  id: node.id,
                  creditAmount: node.creditAmount,
                  productId: node.product ? {
                    key: get(node, 'product.id', ''),
                    label: (
                      <div>
                        <img width="20" height="20" src={get(node, 'product.mainImage.url')} alt={get(node, 'product.name')} />
                        {get(node, 'product.name')}
                      </div>
                    ),
                  } : null,
                }))}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <div key={key}>
                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => getFieldValue(["creditFromProducts", name, "id"]) ? (
                            <FormItem
                              name={[name, "id"]}
                              rules={[{ required: true, message: 'required' }]}
                              hidden
                            >
                              <Input />
                            </FormItem>
                          ) : null}
                        </FormItem>

                        <FormItem
                          label="Product"
                          name={[name, "productId"]}
                          rules={[{ required: true, message: 'required' }]}
                          style={{ width: '300px', display: 'inline-block' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <SelectProduct
                            placeholder="Products"
                            labelInValue
                            showSearch
                            filterOption={false}
                            style={{ width: '300px' }}
                            viewer={viewer}
                          />
                        </FormItem>

                        <FormItem
                          label="Credit"
                          name={[name, "creditAmount"]}
                          rules={[{ required: true, message: 'required' }]}
                          style={{ width: '150px', display: 'inline-block' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <InputNumber style={{ width: '150px' }} placeholder="Credit Amount" />
                        </FormItem>

                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ cursor: 'pointer' }}
                            disabled={fields.length === 1}
                            onClick={() => remove(name)}
                          />
                        ) : null}
                      </div>
                    ))}
                    <Button onClick={() => add()}>
                      <PlusOutlined />
                    </Button>
                  </>
                )}
              </Form.List>
            </FormItem>
            )}

            {this.state.defaultMode === 'price' && (
            <FormItem
              {...formItemLayout}
              label="Price Tiers"
            >
              <Form.List name="tiers" initialValue={initProducts.map(({ node }) => node)}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <div key={key}>
                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => getFieldValue(["tiers", name, "id"]) ? (
                            <FormItem
                              name={[name, "id"]}
                              rules={[{ required: true, message: 'required' }]}
                              hidden
                            >
                              <Input />
                            </FormItem>
                        ) : null}
                        </FormItem>

                        <FormItem
                          label="Price"
                          name={[name, "price"]}
                          rules={[{ required: true, message: 'required' }]}
                          style={{ width: '87px', display: 'inline-block' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <InputNumber placeholder="Price" />
                        </FormItem>

                        <FormItem
                          label="Credit"
                          name={[name, "creditAmount"]}
                          rules={[{ required: true, message: 'required' }]}
                          style={{ width: '147px', display: 'inline-block' }}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <InputNumber style={{ width: '100%' }} placeholder="Credit Amount" />
                        </FormItem>

                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ cursor: 'pointer' }}
                            disabled={fields.length === 1}
                            onClick={() => remove(name)}
                          />
                      ) : null}
                      </div>
                  ))}
                    <Button onClick={() => add()}>
                      <PlusOutlined />
                    </Button>
                  </>
                )}
              </Form.List>
            </FormItem>
            )}

            {this.state.defaultMode === 'percentage' && (
            <FormItem
              {...formItemLayout}
              label="Percentage"
              style={{ marginBottom: '0px' }}
              required
            >
              <div style={{ display: 'flex', alignItems: 'baseline', gap: '5px' }}>
                <span> Spend </span>
                <FormItem
                  name="minSpent"
                  rules={[{ required: true, message: 'required' }]}
                  initialValue={credit.minSpent}
                >
                  <InputNumber style={{ width: '100px' }} min={0} placeholder="Min Amount" />
                </FormItem>
                <span> Earn </span>
                <FormItem
                  name="percentage"
                  rules={[{ required: true, message: 'required' }]}
                  initialValue={credit.percentage}
                >
                  <InputNumber style={{ width: '50px' }} min={1} placeholder="%" />
                </FormItem>
                <span> % </span>
              </div>
            </FormItem>
            )}

            <FormItem
              {...formItemLayout}
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={credit.startDate ? moment(credit.startDate) : null}
            >
              <DatePicker showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} placeholder="Start Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={credit.endDate ? moment(credit.endDate) : null}
              help={<div>If promotion ends on the 2020-01-01, the input should be: <b>2020-01-01 23:59:59</b></div>}
            >
              <DatePicker showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }} placeholder="End Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Credit Expiry"
              extra='Credit will expire in "days"'
              name="expiry"
              rules={[{ required: true, message: 'required' }]}
              initialValue={credit.expiry}
            >
              <InputNumber style={{ width: '100px' }} min={0} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Spending Mode"
              name="applyCreditMode"
              initialValue={credit.applyCreditMode}
            >
              <Select placeholder="Select Brand" showSearch optionFilterProp="children" optionLabelProp="children">
                <Option key={1} value={1}>Include Some Brands</Option>
                <Option key={0} value={0}>Exclude Some Brands</Option>
              </Select>
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {(form) => (
                <FormItem
                  {...formItemLayout}
                  label={this.label(form)}
                  name="applyBrandIds"
                  initialValue={credit.applyBrandIds}
                  extra={`${get(credit, 'applyBrandIds', []).length} selected`}
                >
                  <Select placeholder="Select Brand" allowClear mode="multiple" optionFilterProp="children" optionLabelProp="children">
                    {
                      brands.map((edge) => {
                        const brand = edge.node;
                        return <Option key={brand.id} value={brand.id}>{brand.name}</Option>;
                      })
                    }
                  </Select>
                </FormItem>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Exclude Products From Spending"
              extra="These products will be excluded from spending credits"
              name="excludeProductsSpend"
              initialValue={get(credit, 'excludeProductsSpend.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
            >
              <SelectProduct
                placeholder="Exclude Products"
                mode="multiple"
                labelInValue
                filterOption={false}
                viewer={viewer}
                importExport={{
                  onImport: (value) => { SelectProduct.updateSelect(this.formRef.current, value, 'excludeProductsSpend'); },
                  listToExport: get(credit, 'excludeProductsSpend.edges', [])
                }}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Promo Items(Max. 4 Items)"
              name="promoItems"
              initialValue={get(credit, 'promoItems.edges', []).map((edge) => SelectProduct.productOptions(edge.node))}
              extra="Max. 4 Items"
            >
              <SelectProduct
                placeholder="Promo Items"
                mode="multiple"
                labelInValue
                filterOption={false}
                viewer={viewer}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Stealth Mode"
              name="stealthMode"
              initialValue={get(credit, 'stealthMode', 0) ? 1 : 0}
              extra="promotional messages will be hidden"
            >
              <Select placeholder="Stealth Mode">
                <Option value={1}>Yes</Option>
                <Option value={0}>No</Option>
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(credit, 'status', 0) ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>
          </TabPane>

          <TabPane tab="Orders" key="orders" >
            {this.renderOrders(get(credit.customerStoreCredits, 'edges', []))}
          </TabPane>
        </Tabs>
      </Form>
    );
  }
}
export default createFragmentContainer(CreditForm, {
  viewer: graphql`
    fragment CreditForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
    ) {
      ...SelectProduct_viewer
      brands(first: 1000, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
