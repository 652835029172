import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { Link } from 'found';
import { Affix, Layout, Menu } from 'antd';
import 'antd/dist/antd.less';

import { Logout } from './auth';
import WebsiteBtn from './website/WebsiteBtn';
import '../../public/css/base.css';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

class App extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
      roles: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      })
    }),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
    viewer: null,
  }

  constructor(props) {
    super(props);

    if (!props.viewer) {
      if (props.match.location.pathname !== '/') {
        props.router.push('/');
        window.location.reload();
      } else {
        props.router.push('/');
      }
    }
  }

  renderMenu = (viewer, roles) => {
    if (roles.length === 0) {
      return null;
    } else if (roles[0] === 'Sales') {
      return (
        <Menu theme="dark" mode="inline">
          <Menu.Item key="/sales/order">
            <Link to="/sales/order" style={{ display: 'inline-block' }}>Orders</Link>
          </Menu.Item>
          <Menu.Item key="/store">
            <Link to="/store" style={{ display: 'inline-block' }}>Stores</Link>
          </Menu.Item>
          <SubMenu key="report" title="Report">
            <Menu.Item key="/sales-report">
              <Link to="/report/sales-report" style={{ display: 'inline-block' }}>Sales Report</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="logout">
            <Logout viewer={viewer} />
          </Menu.Item>
        </Menu>
      )
    } else if (roles[0] === 'IT' && roles.length === 1) {
      return (
        <Menu theme="dark" mode="inline">
          <SubMenu key="store" title="Store">
            <Menu.Item key="/store">
              <Link to="/store" style={{ display: 'inline-block' }}>Stores</Link>
            </Menu.Item>
            <Menu.Item key="/store/state-manager">
              <Link to="/store/state-manager" style={{ display: 'inline-block' }}>State Managers</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="/security">
            <Link to="/security" style={{ display: 'inline-block' }}>Security</Link>
          </Menu.Item>
          <Menu.Item key="logout">
            <Logout viewer={viewer} />
          </Menu.Item>
        </Menu>
      )
    }

    return (
      <Menu theme="dark" mode="inline">
        <Menu.Item key="/product">
          <Link to="/product" style={{ display: 'inline-block' }}>Products</Link>
        </Menu.Item>
        <SubMenu key="promotion" title="Promotions">
          <Menu.Item key="/bonus">
            <Link to="/promotion/bonus" style={{ display: 'inline-block' }}>Bonuses</Link>
          </Menu.Item>
          <Menu.Item key="/redemption">
            <Link to="/promotion/redemption" style={{ display: 'inline-block' }}>Redemptions</Link>
          </Menu.Item>
          <Menu.Item key="/label">
            <Link to="/promotion/label" style={{ display: 'inline-block' }}>Labels</Link>
          </Menu.Item>
          <Menu.Item key="/credit">
            <Link to="/promotion/credit" style={{ display: 'inline-block' }}>Credits</Link>
          </Menu.Item>
          <Menu.Item key="/common-promo">
            <Link to="/promotion/common-promo" style={{ display: 'inline-block' }}>Common Promo</Link>
          </Menu.Item>
          <Menu.Item key="/freebie">
            <Link to="/promotion/freebie" style={{ display: 'inline-block' }}>Freebies</Link>
          </Menu.Item>
          <Menu.Item key="/combo-freebie">
            <Link to="/promotion/combo-freebie" style={{ display: 'inline-block' }}>Combo Freebies</Link>
          </Menu.Item>
          <Menu.Item key="/adjacent-banner">
            <Link to="/promotion/adjacent-banner" style={{ display: 'inline-block' }}>Adjacent Banners</Link>
          </Menu.Item>
          <Menu.Item key="/coupon">
            <Link to="/promotion/coupon" style={{ display: 'inline-block' }}>Coupons</Link>
          </Menu.Item>
          <Menu.Item key="/alert">
            <Link to="/promotion/alert" style={{ display: 'inline-block' }}>Alerts</Link>
          </Menu.Item>
          <Menu.Item key="/purchase-limit">
            <Link to="/promotion/purchase-limit" style={{ display: 'inline-block' }}>Purchase Limit</Link>
          </Menu.Item>
          <Menu.Item key="/card">
            <Link to="/promotion/card" style={{ display: 'inline-block' }}>Cards</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/sales/order">
          <Link to="/sales/order" style={{ display: 'inline-block' }}>Orders</Link>
        </Menu.Item>
        <SubMenu key="gift-card" title="Gift Cards">
          <Menu.Item key="/setup">
            <Link to="/gift-card/setup" style={{ display: 'inline-block' }}>Setup</Link>
          </Menu.Item>
          <Menu.Item key="/usage">
            <Link to="/gift-card/usage" style={{ display: 'inline-block' }}>Usages</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/customer">
          <Link to="/customer" style={{ display: 'inline-block' }}>Customers</Link>
        </Menu.Item>
        <Menu.Item key="/sales/quote">
          <Link to="/sales/quote" style={{ display: 'inline-block' }}>Quotes</Link>
        </Menu.Item>
        <Menu.Item key="/subcategory">
          <Link to="/subcategory" style={{ display: 'inline-block' }}>Sub Categories</Link>
        </Menu.Item>
        <Menu.Item key="/category">
          <Link to="/category" style={{ display: 'inline-block' }}>Categories</Link>
        </Menu.Item>
        <Menu.Item key="/brand">
          <Link to="/brand" style={{ display: 'inline-block' }}>Brands</Link>
        </Menu.Item>
        <Menu.Item key="/attribute">
          <Link to="/attribute" style={{ display: 'inline-block' }}>Attributes</Link>
        </Menu.Item>
        <SubMenu key="cms" title="CMS">
          <Menu.Item key="/carousel">
            <Link to="/cms/carousel" style={{ display: 'inline-block' }}>Carousel</Link>
          </Menu.Item>
          <Menu.Item key="/banner">
            <Link to="/cms/banner" style={{ display: 'inline-block' }}>Banner</Link>
          </Menu.Item>
          <Menu.Item key="/page">
            <Link to="/cms/page" style={{ display: 'inline-block' }}>CMS Pages</Link>
          </Menu.Item>
          <Menu.Item key="/faq">
            <Link to="/cms/faq" style={{ display: 'inline-block' }}>FAQ</Link>
          </Menu.Item>
          <Menu.Item key="/footer">
            <Link to="/cms/footer" style={{ display: 'inline-block' }}>Footer</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/review">
          <Link to="/review" style={{ display: 'inline-block' }}>Reviews</Link>
        </Menu.Item>
        <Menu.Item key="/price-match">
          <Link to="/price-match" style={{ display: 'inline-block' }}>Price Matches</Link>
        </Menu.Item>
        <SubMenu key="store" title="Store">
          <Menu.Item key="/store">
            <Link to="/store" style={{ display: 'inline-block' }}>Stores</Link>
          </Menu.Item>
          <Menu.Item key="/store/state-manager">
            <Link to="/store/state-manager" style={{ display: 'inline-block' }}>State Managers</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/user">
          <Link to="/user" style={{ display: 'inline-block' }}>User Management</Link>
        </Menu.Item>
        <SubMenu key="search" title="Search">
          <Menu.Item key="/search-terms-report">
            <Link to="/search/search-terms-report" style={{ display: 'inline-block' }}>Search Terms Report</Link>
          </Menu.Item>
          <Menu.Item key="/synonym">
            <Link to="/search/synonym" style={{ display: 'inline-block' }}>Synonyms</Link>
          </Menu.Item>
          <Menu.Item key="/redirect">
            <Link to="/search/redirect" style={{ display: 'inline-block' }}>Redirects</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/url/rewrite">
          <Link to="/url/rewrite" style={{ display: 'inline-block' }}>Url Redirects</Link>
        </Menu.Item>
        <SubMenu key="report" title="Report">
          <Menu.Item key="/sales-report">
            <Link to="/report/sales-report" style={{ display: 'inline-block' }}>Sales Report</Link>
          </Menu.Item>
          <Menu.Item key="/sales-trend">
            <Link to="/report/sales-trend" style={{ display: 'inline-block' }}>Sales Trend</Link>
          </Menu.Item>
          <Menu.Item key="/by-subcategory">
            <Link to="/report/by-subcategory" style={{ display: 'inline-block' }}>By Subcategory</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/security">
          <Link to="/security" style={{ display: 'inline-block' }}>Security</Link>
        </Menu.Item>
        <Menu.Item key="/config">
          <Link to="/config" style={{ display: 'inline-block' }}>Configurations</Link>
        </Menu.Item>
        <Menu.Item key="logout">
          <Logout viewer={viewer} />
        </Menu.Item>
      </Menu>
    )
  }

  render() {
    const { viewer, children } = this.props;
    let roles = [];

    if (viewer) {
      roles = viewer.roles.edges.map(({ node }) => node.name);
    }

    return (
      <div>
        <Layout>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
          >
            <div className="logo" />
            {this.renderMenu(viewer, roles)}
            <Affix>
              <WebsiteBtn style={{marginLeft: '24px'}} />
            </Affix>
          </Sider>
          <Layout>
            <Content
              style={{
                margin: '24px 16px',
                padding: 24,
                background: '#fff',
                minHeight: 280,
              }}
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}
export default createFragmentContainer(App, {
  viewer: graphql`
    fragment App_viewer on Admin {
      id
      username
      roles(first: 99){
        edges {
          node {
            name
          }
        }
      }
      ...Logout_viewer
    }
  `,
});
