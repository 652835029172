import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { get, difference, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { Affix, Button, Card, Col, Divider, Form, Input, InputNumber, List, message, notification, Popconfirm, Radio, Row, Select, Switch, Tabs, Tag, Upload } from 'antd';
import { ArrowRightOutlined, DeleteOutlined, LinkOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import { fromGlobalId, getLink } from '~/helper';
import { DatePicker, Editor, useFormBase, fileValidator, formItemLayout, isDataEntry, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';
import { UrlRefresher } from '~/components/url';
import BundleProduct from './BundleProduct';
import ConfigProduct from './ConfigProduct';
import ProductHistory from './ProductHistory';
import PriceCheck from './PriceCheck';
import { RemoveProductMutation, RemoveProductFileMutation } from './mutations';
import TierPrice from './TierPrice';
import Alert from './Alert';
import Sorting from './Sorting';
import Related from './Related';
import { ExtraMsg } from './helper';
import WebsiteRef, { Loading } from './WebsiteRef';
import { ScheduleTable } from './bulkActions/UpdatePricesSchedule';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { COUNTRY } = process.env;

export const loopError = function loopError(errors) {
  return errors.map((e) => `${e.name} ${e.errors}`);
};

const updateNzReminder = (websiteReference) => {
  const key = 'updateNzReminder';

  if (COUNTRY === 'AU' && websiteReference) {
    const { country, id } = websiteReference;
    notification.close(key);

    notification.warning({
      key,
      message: 'Reminder',
      description: (
        <>
          <span>Please also update the relevant field(s) in </span>
          <a href={getLink("product", country, id)} target="_blank" rel="noopener noreferrer" style={{ textWrap: 'nowrap' }}>
            {country} Version <LinkOutlined />
          </a>.
        </>
      ),
      duration: 10,
    });
  }
};

const validateAdenCode = (code) => {
  const codeRegex = /^QTY\w+\*NS(\w*)\*05$/;
  return codeRegex.test(code.trim());
};

const bundleWithAdenCode = (isBundle, adenCode) => {
  const validAdenCode = !isEmpty(adenCode) && validateAdenCode(adenCode);

  return isBundle && !validAdenCode;
}

const useRefetch = (props, form) => {
  /*
   * Refetch attributes for all related subcategories
   */
  const refetch = (subcategoryIds = [], attrs = {}) => {
    if (Array.isArray(subcategoryIds)) {
      const attributesRefetch = [];
      if (attrs) {
        const keys = Object.keys(attrs);

        for (let i = 0, l = keys.length; i < l; i += 1) {
          const id = keys[i];
          attributesRefetch.push({ id, options: attrs[id] });
        }
      }

      const refetchVariables = () => ({
        subcategoryIds,
        attributes: attributesRefetch,
      });

      props.relay.refetch(refetchVariables, null, () => {
        // reset field value if new options come in
        props.viewer.attributes.edges.forEach((edge) => {
          const { code, options } = edge.node;
          const fieldName = ["attributes", code];

          let val = form.getFieldValue(fieldName);
          if (val) {
            if (!Array.isArray(val)) {
              val = [val];
            }

            if (difference(val, options).length !== 0) {
              form.setFieldValue(fieldName, null);
            }
          }
        });
      });
    }
  }

  const initRefetch = (attributes, product) => {
    // fetch available attributes

    const attrs = {};
    if (attributes.length && product.attributes && Object.keys(product.attributes).length) {
      const keys = Object.keys(product.attributes);
      for (let i = 0, l = keys.length; i < l; i += 1) {
        const code = keys[i];
        const edge = attributes.find(attrEdge => attrEdge.node.code === code);

        const options = product.attributes[code];
        if (Array.isArray(options) || typeof options === 'string') {
          attrs[edge.node.id] = options;
        }
      }
    }

    refetch(product.subcategoryIds, attrs);
  }

  return [initRefetch, refetch];
}

const ProductForm = (props) => {
  const { match, viewer } = props;
  const product = get(props, 'product', {});
  const brands = get(viewer, 'brands.edges', []);
  const subcategories = get(viewer, 'subcategories.edges', []);
  const categories = get(viewer, 'categories.edges', []);
  const attributes = get(viewer, 'attributes.edges', []);

  const dataEntry = isDataEntry(viewer);
  const isSyncField = COUNTRY === 'NZ' && !!get(product, 'websiteReference');

  const { handleDisableBtn, shouldDisableBtn } = useFormBase();

  const [form] = Form.useForm();
  const productType = Form.useWatch('type', form);
  const mainImageId = Form.useWatch('mainImageId', form);
  const feedImageId = Form.useWatch('feedImageId', form);
  const adenCode = Form.useWatch('adenCode', form);
  const stockAvailable = Form.useWatch('stockAvailable', form);

  const [initRefetch, refetch] = useRefetch(props, form);

  const isBundle = productType === "bundle";
  const isBundleAC = bundleWithAdenCode(isBundle, adenCode);

  const saveVideoInputRef = useRef(null);

  const [state, setState] = useState({
    disableStatus: false,
    magicPhrase: "",
    videoInputVisible: false,
    videoInputValue: '',
    videos: get(product, 'videos') || [],
    imageFileList: [],
    fileList: [],
    length: {},
    width: {},
    height: {},
    dimensionUnit: isEmpty(product) ? null : 'Centimetre',
    lengthMM: get(product, 'length') * 10 || null,
    widthMM: get(product, 'width') * 10 || null,
    heightMM: get(product, 'height') * 10 || null,
  });

  useEffect(() => {
    initRefetch(attributes, product);
  }, []);

  useEffect(() => {
    if (isBundle && !product.id) {
      form.setFieldValue("weight", 0.1);
    }
  }, [isBundle]);

  useEffect(() => {
    const { current: input } = saveVideoInputRef;
    if (state.videoInputVisible && input) {
      input.focus();
    }
  }, [state.videoInputVisible]);

  const onSubmitCompleted = () => {
    setState((s) => ({
      ...s,
      imageFileList: [],
      fileList: [],
    }));

    const websiteReference = get(props, 'product.websiteReference');
    updateNzReminder(websiteReference);
  };

  const getShowingAttributes = () => {
    const attrs = {};
    attributes.forEach(({ node }) => {
      const { code, id } = node;
      const attr = form.getFieldValue(["attributes", code]);
      if (attr) {
        attrs[id] = attr;
      }
    });

    return attrs;
  }

  const handleVideoInputConfirm = (e) => {
    // prevent form from submitting
    e.preventDefault();

    const { videoInputValue } = state;

    const videos = form.getFieldValue('videos');

    let newVideos = new Set(videos);

    newVideos.add(videoInputValue);
    newVideos = Array.from(newVideos);

    setState((s) => ({
      ...s,
      videoInputValue: '',
      videos: newVideos,
    }));
    form.setFieldsValue({ videos: newVideos });
  }

  const handleVideoInputChange = (e) => {
    const { value: videoInputValue } = e.target;
    setState((s) => ({ ...s, videoInputValue }));
  }

  const handleVideoRemove = (videoId, e) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure?')) {
      const videos = form.getFieldValue('videos');
      let newVideos = new Set(videos);

      newVideos.delete(videoId);
      newVideos = Array.from(newVideos);

      setState((s) => ({ ...s, videos: newVideos }));
      form.setFieldsValue({ videos: newVideos });
    } else {
      e.preventDefault();
    }
  }

  const handleImport = (value, field) => {
    SelectProduct.updateSelect(form, value, field);
  }

  const showVideoInput = () => {
    setState((s) => ({
      ...s,
      videoInputVisible: true,
    }));
  }

  const changeMainImage = (e) => {
    form.setFieldsValue({
      mainImageId: e.target.value,
    });
  }

  const changeFeedImage = (e) => {
    form.setFieldsValue({
      feedImageId: e.target.value,
    });
  }

  const disabledDate = current => current && current < moment().startOf('day')

  const deleteImage = (i) => {
    props.deleteImage(i, form);
  }

  const deleteFile = (file) => {
    const { id } = file;

    const mutation = {
      environment: props.relay.environment,
      variables: { input: { id } },
      parent: { id: product.id },
      viewer: props.viewer
    };

    RemoveProductFileMutation.commit(mutation);
  }

  const deleteProduct = () => {
    const { id } = get(props, 'product', {});
    const { magicPhrase } = state;

    const mutation = {
      environment: props.relay.environment,
      variables: { input: { id, magicPhrase } },
      viewer: props.viewer,
      onError: (errors) => {
        message.error(errors[0].message);
      },
      onCompleted: () => {
        message.success('Saved');
        props.router.push('/product');
      },
    };

    RemoveProductMutation.commit(mutation);
  }

  /*
   * make product name by `${brand} ${model} ${partialname}`
   */
  const updateProductName = (field, value) => {
    // filter out the supplied field
    const formFields = ["brandId", "model", 'secondModel', "partialName"].filter(i => i !== field);

    // merge form values with event value supplied
    const f = Object.assign(form.getFieldsValue(formFields), { [field]: value });

    // get brand name by node ID
    const brand = brands.find(edge => edge.node.id === f.brandId);
    const brandName = brand ? brand.node.name : '';

    const name = `${brandName} ${f.model || ''}${f.secondModel ? ` (${f.secondModel})` : ''} ${f.partialName || ''}`;


    form.setFieldsValue({ name });
  }

  const parsePrice = (value) => {
    if (typeof value === 'undefined') {
      return null;
    }

    if (typeof value === 'string' && value.length === 0) {
      return null;
    }

    return value;
  }

  const validateNumber = (value) => {
    if (value > 100) {
      return {
        validateStatus: 'warning',
        errorMsg: 'Are you sure the value is greater than 1 METRE!',
      };
    }

    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  }

  const onNumberChange = (value, field) => {
    setState((s) => ({
      ...s,
      [field]: {
        ...validateNumber(value)
      }
    }));
  };

  const checkAdenCode = async (rule, value) => {
    if (value && value.length > 0) {
      if (!validateAdenCode(value)) {
        return Promise.reject(new Error("Invalid Aden Code"));
      }
    }
    return Promise.resolve();
  };

  const stockDateValidator = async (rule, value) => {
    if (value && value.format('YYYY-MM-DD') === '9999-12-31') {
      return Promise.reject(new Error('9999-12-31 is not allowed anymore. Use a real date and switch on Hide Stock Date to hide date'));
    }
    return Promise.resolve();
  }

  const changeDimensionUnitSelect = (e) => {
    const { value: dimensionUnit } = e.target;
    setState((s) => ({ ...s, dimensionUnit }));
  }

  const syncCentimetreValue = (value, field) => {
    onNumberChange(value / 10, field)
    switch (field) {
      case 'length':
        setState((s) => ({ ...s, lengthMM: value }))
        form.setFieldsValue({ length: value ? value / 10 : null })
        break
      case 'width':
        setState((s) => ({ ...s, widthMM: value }))
        form.setFieldsValue({ width: value ? value / 10 : null })
        break
      case 'height':
        setState((s) => ({ ...s, heightMM: value }))
        form.setFieldsValue({ height: value ? value / 10 : null })
        break
      default:
        break
    }
  }

  return (
    <Form
      form={form}
      onFinish={(values) => { props.onSubmit(form, onSubmitCompleted, attributes, values); }}
      onFinishFailed={({ errorFields }) => {
        const messages = loopError(errorFields);
        // eslint-disable-next-line react/no-danger
        message.warning(<div dangerouslySetInnerHTML={{ __html: messages.join('<br/>') }} />);
      }}
    >

      <Affix>
        <div>
          <Presence match={match} disableButton={handleDisableBtn} />
          <Button type="primary" htmlType="submit" disabled={shouldDisableBtn} style={{marginRight: '10px'}}>Save</Button>

          {product.id && (
            <React.Suspense fallback={<Loading />}>
              <WebsiteRef viewer={viewer} product={product} />
            </React.Suspense>
          )}

          {product.id && (
            <Popconfirm
              title={
                <div>
                  What is the <b>magic phrase</b>?
                  <Input
                    onChange={(e) => {
                      const { value: magicPhrase } = e.target;
                      setState((s) => ({ ...s, magicPhrase }));
                    }}
                  />
                </div>
              }
              onConfirm={deleteProduct}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" htmlType="button">Delete</Button>
            </Popconfirm>
          )}

          {product.id && (
            <Button
              href={`/product/copy?id=${fromGlobalId(product.id).id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Copy
            </Button>
          )}
        </div>
      </Affix>

      <Tabs defaultActiveKey="general">
        <TabPane tab="General" key="general">
          <FormItem
            name="id"
            initialValue={product.id}
            hidden
          >
            <Input />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Type"
            name="type"
            rules={[{ required: true, message: 'required' }]}
            initialValue={get(product, 'type', null)}
          >
            <Select placeholder="Bundle/Simple/Configurable">
              <Option value="simple">Simple</Option>
              <Option value="bundle">Bundle</Option>
              <Option value="configurable">Configurable</Option>
            </Select>
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Partial Name"
            name="partialName"
            rules={[{ required: true, message: 'required' }]}
            initialValue={product.partialName}
            help={<ExtraMsg isSyncField={isSyncField} />}
          >
            <Input
              placeholder="partialName"
              onChange={(e) => updateProductName('partialName', e.target.value)}
              disabled={isSyncField}
            />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Name"
            name="name"
            rules={[{ required: true, message: 'required' }]}
            initialValue={product.name}
          >
            <Input placeholder="Name" readOnly />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Brand"
            name="brandId"
            rules={[{ required: true, message: 'required' }]}
            initialValue={product.brandId}
            help={<ExtraMsg isSyncField={isSyncField} />}
          >

            <Select
              placeholder="Brand"
              optionFilterProp="children"
              showSearch
              onChange={(value) => updateProductName('brandId', value)}
              disabled={isSyncField}
            >
              {
                brands.map((edge) => {
                  const brand = edge.node;
                  return <Option key={brand.id} value={brand.id}>{brand.name}</Option>;
                })
              }
            </Select>
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="SKU"
          >
            <Input placeholder="Sku" disabled value={product.sku} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Model"
            name="model"
            rules={[{ required: true, message: 'required' }]}
            initialValue={product.model}
            extra={<ExtraMsg isSyncField={isSyncField} />}
          >
            <Input placeholder="Model" onChange={(e) => updateProductName('model', e.target.value)} disabled={isSyncField} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Second Model"
            name="secondModel"
            rules={[{ required: false, message: 'required' }]}
            initialValue={product.secondModel}
            extra={<ExtraMsg isSyncField={isSyncField} />}
          >
            <Input placeholder="Second Model" onChange={(e) => updateProductName('secondModel', e.target.value)} disabled={isSyncField} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Aden Code"
            name="adenCode"
            rules={[
              { required: false, message: 'required' },
              { validator: checkAdenCode }
            ]}
            initialValue={product.adenCode}
          >
            <Input placeholder="Aden Code" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Odoo Code"
            name="odooCode"
            rules={[
              { type: "string", required: false, pattern: /[0-9]+/, message: 'Number Only' },
            ]}
            initialValue={product.odooCode}
          >
            <Input placeholder="Odoo Code" style={{ width: '100px'}} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Barcode"
            name="barcode"
            rules={[
              { type: "string", required: false, pattern: /[0-9]+/, message: 'Number Only' },
            ]}
            initialValue={product.barcode}
            extra={<span style={{ color: 'red' }}>Barcode is important and required for simple products</span>}
          >
            <Input placeholder="Barcode" style={{ width: '200px'}} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="URL"
            extra={<ExtraMsg isSyncField={isSyncField} />}
          >
            <div>
              <Input placeholder="URL" value={get(product, 'urlSlug')} readOnly disabled={isSyncField} />
              <UrlRefresher entity={product} viewer={viewer} disabled={isSyncField} />
              <UrlRefresher
                entity={product}
                viewer={viewer}
                disabled={isSyncField}
                rewrite
              >
                Refresh And Rewrite
              </UrlRefresher>
            </div>
          </FormItem>

          <Sorting form={form} product={Object.keys(product).length === 0 ? null : product} />

          <Divider />
          <FormItem
            {...formItemLayout}
            label="Free Shipping"
            name="freeShipping"
            valuePropName="checked"
            rules={[{ required: true, message: 'required' }]}
            initialValue={get(product, 'freeShipping', false)}
          >
            <Switch />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Free Shipping Start Date"
            name="freeShippingStartDate"
            rules={[{ required: false, message: 'required' }]}
            initialValue={product.freeShippingStartDate ? moment(product.freeShippingStartDate) : null}
          >
            <DatePicker placeholder="Free Shipping Start Date" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Free Shipping End Date"
            name="freeShippingEndDate"
            rules={[{ required: false, message: 'required' }]}
            initialValue={product.freeShippingEndDate ? moment(product.freeShippingEndDate) : null}
          >
            <DatePicker placeholder="Free Shipping End Date" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Hide Global Free Shipping"
            name="hideGlobalFreeShipping"
            valuePropName="checked"
            rules={[{ required: false, message: 'required' }]}
            initialValue={get(product, 'hideGlobalFreeShipping', false)}
          >
            <Switch />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Exclude Global Free Shipping"
            name="excludeGlobalFreeShipping"
            valuePropName="checked"
            initialValue={get(product, 'excludeGlobalFreeShipping', false)}
            extra="Exclude this product from the Global Free Shipping promotion"
          >
            <Switch />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Exclude Express Shipping"
            name="excludeExpressShipping"
            valuePropName="checked"
            initialValue={get(product, 'excludeExpressShipping')}
            extra="Not offering Express Shipping on this product"
          >
            <Switch />
          </FormItem>

          <Divider />

          <FormItem
            {...formItemLayout}
            label="Visibility"
            name="visibility"
            rules={[{ required: true, message: 'required' }]}
            initialValue={get(product, 'visibility', 4)}
          >
            <Select placeholder="Visibility">
              <Option value={1}>Hidden</Option>
              <Option value={2}>Catalog</Option>
              <Option value={3}>Search</Option>
              <Option value={4}>Catalog & Search</Option>
              <Option value={5}>Link Only</Option>
            </Select>
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {({ getFieldValue }) => getFieldValue('visibility') === 5 && (
              <FormItem
                {...formItemLayout}
                label="Link Only Days"
                name="linkOnlyDays"
                initialValue={get(product, 'linkOnlyDays')}
                extra="Visibility becomes `Catalog & Search` when Link Only product is older than specified days"
              >
                <InputNumber min={1} />
              </FormItem>
            )}
          </FormItem>

          <Divider />

          <FormItem
            {...formItemLayout}
            label="Status"
            name="status"
            rules={[{ required: true, message: 'required' }]}
            initialValue={get(product, 'status', 'disabled')}
            extra="End of Life products might be deleted randomly without notice"
          >
            <Select
              placeholder="Status"
              disabled={dataEntry || state.disableStatus}
            >
              <Option value="enabled">Enabled</Option>
              <Option value="disabled">Disabled</Option>
              <Option value="end-of-life">End Of Life</Option>
            </Select>
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Status Enable From To"
            name="enableFromTo"
            rules={[{ required: false, message: 'required' }]}
            initialValue={[
              product.enableStartDate ? moment(product.enableStartDate) : null,
              product.enableEndDate ? moment(product.enableEndDate) : null
            ]}
          >
            <RangePicker
              disabled={dataEntry}
              disabledDate={disabledDate}
              onChange={(date) => {
                let disableStatus = false;
                if (date) {
                  const now = moment();
                  const valid = now.isBetween(date[0].startOf('day'), date[1].endOf('day'));

                  let status = "disabled";
                  if (valid) {
                    status = "enabled";
                  }

                  form.setFieldsValue({ status });
                  disableStatus = true;
                }

                setState((s) => ({ ...s, disableStatus }));
              }}
              format="YYYY-MM-DD"
            />
          </FormItem>

          <Divider />

          <FormItem
            {...formItemLayout}
            label="In Promo Card"
            name="promoCard"
            valuePropName="checked"
            initialValue={product.promoCard}
          >
            <Switch />
          </FormItem>

        </TabPane>

        {productType === 'bundle' && (
          <TabPane tab="Bundles" key="bundles" forceRender>
            <BundleProduct
              viewer={viewer}
              product={product}
            />
          </TabPane>
        )}

        {productType === 'configurable' && (
          <TabPane tab="Configurables" key="configurables" forceRender>
            <ConfigProduct
              viewer={viewer}
              product={product}
              form={form}
            />
          </TabPane>
        )}

        <TabPane tab="Categories" key="categories" forceRender>
          <FormItem
            {...formItemLayout}
            label="Subcategories"
            name="subcategoryIds"
            rules={[{ required: true, message: 'required' }]}
            initialValue={product.subcategoryIds}
          >
            <Select
              placeholder="Subcategories"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const span = option.props.children;
                return span.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              mode="multiple"
              onSelect={(value) => {
                const ids = form.getFieldValue('subcategoryIds') || [];
                const newIds = [].concat(ids, [value]);

                const attrs = getShowingAttributes();
                refetch(newIds, attrs);
              }}
              onDeselect={(value) => {
                let ids = form.getFieldValue('subcategoryIds') || [];
                ids = ids.filter(id => id !== value);
                const attrs = getShowingAttributes();
                refetch(ids, attrs);
              }}
            >
              {
                subcategories.map((edge) => {
                  const e = edge.node;
                  const style = { opacity: e.status ? '100%' : '50%' };
                  return <Option key={e.id} value={e.id}><span style={style}>{e.name}</span></Option>;
                })
              }
            </Select>
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Categories"
            name="categoryIds"
            rules={[{ required: true, message: 'required' }]}
            initialValue={product.categoryIds || "Q2F0ZWdvcnk6Mw=="}
          >
            <Select
              placeholder="Categories"
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const span = option.props.children;
                return span.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                categories.map((edge) => {
                  const e = edge.node;
                  const style = { opacity: e.status ? '100%' : '50%' };
                  return <Option key={e.id} value={e.id}><span style={style}>{e.name}</span></Option>;
                })
              }
            </Select>
          </FormItem>
        </TabPane>

        <TabPane tab="Tool Specs" key="toolspecs" forceRender>
          {
            attributes.map((edge) => {
              const { id, code, name, options, required, multi, } = edge.node;
              const fieldName = ["attributes", code];

              const fieldOptions = {
                rules: [{ required, message: 'required' }],
                initialValue: undefined,
              };

              if (product.attributes) {
                const attrKeys = Object.keys(product.attributes);

                const initialOption = attrKeys.find(k => k === code);

                if (initialOption) {
                  fieldOptions.initialValue = product.attributes[initialOption];
                }
              }

              return (
                <FormItem
                  key={code}
                  {...formItemLayout}
                  label={name}
                  name={fieldName}
                  {...fieldOptions}
                >
                  <Select
                    allowClear
                    placeholder={name}
                    mode={multi ? 'multiple' : null}
                    onChange={(value) => {
                      const subcategoryIds = (form.getFieldValue('subcategoryIds') || []).filter(subId => subId !== value);

                      const attrs = {};
                      attributes.forEach((attrEdge) => {
                        const { code: attrCode, id: attrId } = attrEdge.node;
                        attrs[attrId] = form.getFieldValue(["attributes", attrCode]);
                      });
                      attrs[id] = value;
                      refetch(subcategoryIds, attrs);
                    }}
                  >
                    {
                      options && options.map(o => <Option key={o} value={o}>{o}</Option>)
                    }
                  </Select>
                </FormItem>
              );
            })
          }

          <Divider />

        </TabPane>

        <TabPane tab="Pricing" key="pricing" forceRender>
          <FormItem
            {...formItemLayout}
            label="Call For Price"
            name="callForPrice"
            valuePropName="checked"
            rules={[{ required: false, message: 'required' }]}
            initialValue={get(product, 'callForPrice', false)}
          >
            <Switch />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Cost Price"
          >
            <span>$ {product.costPrice}</span>
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Regular Price"
          >
            <FormItem
              name="price"
              rules={[{ required: true, message: 'required' }]}
              initialValue={product.price}
            >
              <InputNumber
                min={0}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                placeholder="Regular Price"
                style={{ width: '120px' }}
              />
            </FormItem>
            <PriceCheck product={product} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Scheduled Prices"
          >
            <ScheduleTable.Small updatePriceSchedules={product.updatePriceSchedules} />
          </FormItem>

          <Divider />

          <FormItem
            {...formItemLayout}
            label="Special Price"
            name="specialPrice"
            rules={[{ required: false, message: 'required' }]}
            initialValue={product.specialPrice}
            getValueFromEvent={parsePrice}
          >
            <InputNumber
              min={0}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Special Price"
              style={{ width: '120px' }}
            />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Special Price Start Date"
            name="specialPriceStartDate"
            rules={[
              ({ getFieldValue }) => ({
                required: getFieldValue('specialPrice'), message: 'required'
              }),
            ]}
            initialValue={product.specialPriceStartDate ? moment(product.specialPriceStartDate) : null}
            shouldUpdate
          >
            <DatePicker disabledDate={(current) => current && current < moment().startOf('day')} placeholder="Special Price Start Date" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Special Price End Date"
            name="specialPriceEndDate"
            rules={[
              ({ getFieldValue }) => ({
                required: getFieldValue('specialPrice'), message: 'required'
              }),
            ]}
            initialValue={product.specialPriceEndDate ? moment(product.specialPriceEndDate) : null}
            shouldUpdate
          >
            <DatePicker disabledDate={(current) => current && current < moment().startOf('day')} placeholder="Special Price End Date" />
          </FormItem>

          <Divider />

          <FormItem
            {...formItemLayout}
            label="Price Drop"
            name={["priceDrop", "enable"]}
            valuePropName="checked"
            rules={[{ required: false, message: 'required' }]}
            initialValue={get(product, 'priceDrop.enable', false)}
          >
            <Switch />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Price Drop Start Date"
            name={["priceDrop", "startDate"]}
            rules={[
              ({ getFieldValue }) => ({
                required: getFieldValue(["priceDrop", "enable"]), message: 'required'
              }),
            ]}
            initialValue={get(product, 'priceDrop.startDate') ? moment(get(product, 'priceDrop.startDate')) : null}
            shouldUpdate
          >
            <DatePicker disabledDate={(current) => current && current < moment().startOf('day')} placeholder="Price Drop Start Date" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Price Drop End Date"
            name={["priceDrop", "endDate"]}
            rules={[
              ({ getFieldValue }) => ({
                required: getFieldValue(["priceDrop", "enable"]), message: 'required'
              }),
            ]}
            initialValue={get(product, 'priceDrop.endDate') ? moment(get(product, 'priceDrop.endDate')) : null}
            shouldUpdate
          >
            <DatePicker disabledDate={(current) => current && current < moment().startOf('day')} placeholder="Price Drop End Date" />
          </FormItem>

          <Divider />

          <FormItem
            {...formItemLayout}
            label="Flyer Price"
            name="flyerPrice"
            rules={[{ required: false, message: 'required' }]}
            initialValue={product.flyerPrice}
            getValueFromEvent={parsePrice}
          >
            <InputNumber
              min={0}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Flyer Price"
              style={{ width: '120px' }}
            />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Flyer Price Start Date"
            name="flyerPriceStartDate"
            rules={[
              ({ getFieldValue }) => ({
                required: getFieldValue('flyerPrice'), message: 'required'
              }),
            ]}
            initialValue={product.flyerPriceStartDate ? moment(product.flyerPriceStartDate) : null}
            shouldUpdate
          >
            <DatePicker disabledDate={(current) => current && current < moment().startOf('day')} placeholder="Flyer Price Start Date" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Flyer Price End Date"
            name="flyerPriceEndDate"
            rules={[
              ({ getFieldValue }) => ({
                required: getFieldValue('flyerPrice'), message: 'required'
              }),
            ]}
            initialValue={product.flyerPriceEndDate ? moment(product.flyerPriceEndDate) : null}
            shouldUpdate
          >
            <DatePicker disabledDate={(current) => current && current < moment().startOf('day')} placeholder="Flyer Price End Date" />
          </FormItem>

          <Divider />

          <FormItem
            {...formItemLayout}
            label="Tier Prices"
          >
            <TierPrice product={product} form={form} />
          </FormItem>

        </TabPane>

        <TabPane tab="Images" key="images" forceRender>
          <p>
            <b>Images will be compressed automatically, you DO NOT have to do it manually.</b><br />
            <b>However, please make sure images are 1000x1000.</b>
          </p>
          <FormItem
            name="newImages"
            rules={[
              { required: false, message: 'required' },
              { required: true, message: 'File cannot be over 2MB', validator: fileValidator.bind(this, 2) }
            ]}
          >
            <Upload
              multiple
              accept="image/gif,image/png,image/jpeg"
              beforeUpload={() => false}
              listType="picture"
              fileList={state.imageFileList}
              onChange={(info) => {
                const fileList = info.fileList.filter((file) => {
                  const isLt2M = file.originFileObj.size / 1024 / 1024 < 2;
                  if (!isLt2M) {
                    message.error('Image must smaller than 2MB!');
                  }
                  return isLt2M;
                });

                setState((s) => ({ ...s, imageFileList: fileList }));
              }}
            >
              <Button>
                <UploadOutlined /> Upload
              </Button>
            </Upload>
          </FormItem>

          <FormItem
            name="mainImageId"
            initialValue={get(product, 'mainImage.id')}
            hidden
          >
            <Input />
          </FormItem>

          <FormItem
            name="feedImageId"
            initialValue={get(product, 'feedImage.id')}
            hidden
          >
            <Input />
          </FormItem>

          <Row gutter={16}>
            {
              product.images && product.images.edges.map((edge) => {
                const i = edge.node;
                let radioWrapperClass;
                let radioSpanClass;
                let feedRadioWrapperClass;
                let feedRadioSpanClass;

                if (mainImageId === i.id) {
                  radioWrapperClass = 'ant-radio-wrapper-checked';
                  radioSpanClass = 'ant-radio-checked';
                }

                if (feedImageId === i.id) {
                  feedRadioWrapperClass = 'ant-radio-wrapper-checked';
                  feedRadioSpanClass = 'ant-radio-checked';
                }

                return (
                  <Col key={i.id} xs={4}>
                    <Card bodyStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div className="ant-upload-list-item">
                        <Popconfirm
                          title="Are you sure to delete this image?"
                          onConfirm={() => { deleteImage(i); }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined title="Remove" style={{ opacity: 1 }} />
                        </Popconfirm>
                      </div>
                      <img alt="" style={{ maxWidth: '100%', height: '168px' }} src={i.url} />
                      <FormItem
                        name={["images", i.id, "id"]}
                        initialValue={i.id}
                        hidden
                      >
                        <Input />
                      </FormItem>

                      <label className={`ant-radio-wrapper ${radioWrapperClass}`}>
                        <span className={`ant-radio ${radioSpanClass}`}>
                          <input type="radio" name="mainImage" className="ant-radio-input" value={i.id} onChange={changeMainImage} />
                          <span className="ant-radio-inner" />
                        </span>
                        <span>Main Image</span>
                      </label>

                      <label className={`ant-radio-wrapper ${feedRadioWrapperClass}`}>
                        <span className={`ant-radio ${feedRadioSpanClass}`}>
                          <input type="radio" name="feedImage" className="ant-radio-input" value={i.id} onChange={changeFeedImage} />
                          <span className="ant-radio-inner" />
                        </span>
                        <span>Feed Image</span>
                      </label>

                      <FormItem
                        label="Position"
                        name={["images", i.id, "position"]}
                        rules={[{ required: false, message: 'required' }]}
                        initialValue={i.position}
                      >
                        <InputNumber placeholder="Position" />
                      </FormItem>

                      <FormItem
                        label="Show In Bundle"
                        name={["images", i.id, "showInKit"]}
                        valuePropName="checked"
                        rules={[{ required: false, message: 'required' }]}
                        initialValue={get(i, 'showInKit', false)}
                      >
                        <Switch />
                      </FormItem>

                      <FormItem
                        label="Status"
                        name={["images", i.id, "status"]}
                        valuePropName="checked"
                        rules={[{ required: false, message: 'required' }]}
                        initialValue={get(i, 'status', false)}
                      >
                        <Switch />
                      </FormItem>

                    </Card>
                  </Col>
                );
              })
            }
          </Row>
        </TabPane>

        <TabPane tab="Videos" key="videos" forceRender>

          <FormItem
            name="videos"
            rules={[{ required: false, message: 'required' }]}
            initialValue={product.videos}
            hidden
          >
            <Input />
          </FormItem>

          {state.videoInputVisible && (
            <Input
              ref={saveVideoInputRef}
              placeholder="Youtube Video ID"
              style={{ width: 130 }}
              value={state.videoInputValue}
              onChange={handleVideoInputChange}
              onPressEnter={handleVideoInputConfirm}
            />
          )}

          {!state.videoInputVisible && (
            <Tag
              onClick={showVideoInput}
            >
              <PlusOutlined /> New Video
            </Tag>
          )}

          {state.videos.map(videoId => (
            <Tag
              key={videoId}
              closable
              onClose={(e) => { handleVideoRemove(videoId, e); }}
            >
              Video ID: {videoId}<br />
              <iframe
                title={videoId}
                width="200"
                height="200"
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                gesture="media"
                allow="encrypted-media"
                allowFullScreen
              />
            </Tag>
          ))}
        </TabPane>

        <TabPane tab="Dimensions & Weight" key="dimensionsWeight" forceRender>
          <FormItem
            {...formItemLayout}
            label="Packaging Weight(kg)"
            name="weight"
            rules={[{ required: true, message: 'required' }]}
            // initialValue won't be changed once it's set, see field name "type" onChange function
            initialValue={isBundle && !product.id ? 0.1 : product.weight}
            help="Kilogram"
            extra={<ExtraMsg isBundle={isBundle} isSyncField={isSyncField} />}
          >
            <InputNumber min={0.1} placeholder="Weight" disabled={isBundle || isSyncField} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Select Dimensions Unit"
            help={
              state.dimensionUnit === "Millimetre"
              && "Note: Any input in millimetres will be converted to centimetres"
            }
          >
            <Radio.Group onChange={(e) => changeDimensionUnitSelect(e)} value={state.dimensionUnit} disabled={isSyncField}>
              <Radio value="Millimetre" style={{ paddingRight: "35px" }}>Millimetre</Radio>
              <Radio value="Centimetre">Centimetre</Radio>
            </Radio.Group>
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Packaging Length"
            help={state.length.errorMsg}
            validateStatus={state.length.validateStatus}
            extra={<ExtraMsg isSyncField={isSyncField} />}
          >
            <InputNumber
              min={1}
              placeholder="Length"
              value={state.lengthMM}
              disabled={state.dimensionUnit !== "Millimetre" || isSyncField}
              onChange={value => syncCentimetreValue(value, 'length')}
            />
            <span style={{ padding: "0px 5px" }}>mm</span>
            <ArrowRightOutlined
              style={{ padding: "0px 5px" }}
            />

            <FormItem
              name="length"
              rules={[{ required: true, message: 'required' }]}
              initialValue={product.length}
              style={{ display: 'inline-block', marginBottom: '0px' }}
            >
              <InputNumber
                min={0.1}
                placeholder="Length"
                disabled={state.dimensionUnit !== "Centimetre" || isSyncField}
                onChange={value => {
                  onNumberChange(value, 'length')
                  setState((s) => ({ ...s, lengthMM: value ? value * 10 : null }))
                }}
              />
            </FormItem>
            <span style={{ padding: "0px 5px" }}>cm</span>
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Packaging Width"
            help={state.width.errorMsg}
            validateStatus={state.width.validateStatus}
            extra={<ExtraMsg isSyncField={isSyncField} />}
          >
            <InputNumber
              min={1}
              placeholder="Width"
              value={state.widthMM}
              disabled={state.dimensionUnit !== "Millimetre" || isSyncField}
              onChange={value => syncCentimetreValue(value, 'width')}
            />
            <span style={{ padding: "0px 5px" }}>mm</span>
            <ArrowRightOutlined
              style={{ padding: "0px 5px" }}
            />

            <FormItem
              name="width"
              rules={[{ required: true, message: 'required' }]}
              initialValue={product.width}
              style={{ display: 'inline-block', marginBottom: '0px' }}
            >
              <InputNumber
                min={0.1}
                placeholder="Width"
                disabled={state.dimensionUnit !== "Centimetre" || isSyncField}
                onChange={value => {
                  onNumberChange(value, 'width')
                  setState((s) => ({ ...s, widthMM: value ? value * 10 : null }))
                }}
              />
            </FormItem>
            <span style={{ padding: "0px 5px" }}>cm</span>
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Packaging Height"
            help={state.height.errorMsg}
            validateStatus={state.height.validateStatus}
            extra={<ExtraMsg isSyncField={isSyncField} />}
          >
            <InputNumber
              min={1}
              placeholder="Height"
              value={state.heightMM}
              disabled={state.dimensionUnit !== "Millimetre" || isSyncField}
              onChange={value => syncCentimetreValue(value, 'height')}
            />
            <span style={{ padding: "0px 5px" }}>mm</span>
            <ArrowRightOutlined
              style={{ padding: "0px 5px" }}
            />

            <FormItem
              name="height"
              rules={[{ required: true, message: 'required' }]}
              initialValue={product.height}
              style={{ display: 'inline-block', marginBottom: '0px' }}
            >
              <InputNumber
                min={0.1}
                placeholder="Height"
                disabled={state.dimensionUnit !== "Centimetre" || isSyncField}
                onChange={value => {
                  onNumberChange(value, 'height')
                  setState((s) => ({ ...s, heightMM: value ? value * 10 : null }))
                }}
              />
            </FormItem>
            <span style={{ padding: "0px 5px" }}>cm</span>
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Bulky Good"
            help={
              <ExtraMsg isSyncField={isSyncField}>
                When enabled, this item won’t be available for click and collect on stores that are excluding Bulky Goods
              </ExtraMsg>
            }
          >
            <FormItem
              name="bulkyGood"
              valuePropName="checked"
              initialValue={get(product, 'bulkyGood', false)}
            >
              <Switch disabled={isSyncField} />
            </FormItem>
          </FormItem>
        </TabPane>

        <TabPane tab="Inventory" key="inventory" forceRender>
          <FormItem
            {...formItemLayout}
            label="Dangerous Good"
            name="dangerousGood"
            valuePropName="checked"
            rules={[{ required: false, message: 'required' }]}
            initialValue={get(product, 'dangerousGood', false)}
          >
            <Switch disabled={isSyncField} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="In Store Only"
            name="inStoreOnly"
            valuePropName="checked"
            rules={[{ required: false, message: 'required' }]}
            initialValue={get(product, 'inStoreOnly', false)}
          >
            <Switch />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Disable Click and Collect"
            name="onlineOnly"
            valuePropName="checked"
            rules={[{ required: false, message: 'required' }]}
            initialValue={get(product, 'onlineOnly', false)}
            extra={
              <div>
                <span>Items will be excluded from store pickup and allocation</span>
                <br />
                <span>Items with <b>Clearance</b> & <b>Stock Levels</b> will also disable click and collect</span>
                <ExtraMsg isBundle={isBundleAC} />
              </div>
            }
          >
            <Switch disabled={isBundleAC} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Non Stock"
            name="nonStock"
            valuePropName="checked"
            rules={[{ required: false, message: 'required' }]}
            initialValue={get(product, 'nonStock', false)}
            extra={
              <div>
                <span>Exclude in same day delivery</span>
                <ExtraMsg isBundle={isBundleAC} />
              </div>
            }
          >
            <Switch disabled={isBundleAC} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Back In Stock Date"
            name="backInStockDate"
            rules={[{ validator: stockDateValidator }]}
            initialValue={product.backInStockDate ? moment(product.backInStockDate) : null}
            extra={<ExtraMsg isBundle={isBundleAC} />}
          >
            <DatePicker placeholder="Back In Stock" disabled={isBundleAC} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Preorder Date"
            name="preorderDate"
            rules={[{ validator: stockDateValidator }]}
            initialValue={product.preorderDate ? moment(product.preorderDate) : null}
            extra={<ExtraMsg isBundle={isBundleAC} />}
          >
            <DatePicker placeholder="Preorder" disabled={isBundleAC} />
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {({ getFieldValue }) => (getFieldValue('backInStockDate') || getFieldValue('preorderDate')) && (
            <FormItem
              {...formItemLayout}
              label="Hide Stock Date"
              name="hideStockDate"
              valuePropName="checked"
              initialValue={product.hideStockDate}
              extra={
                <div>
                  <span>Switch on to hide date</span>
                  <ExtraMsg isBundle={isBundleAC} />
                </div>
              }
            >
              <Switch disabled={isBundleAC} />
            </FormItem>
          )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Stock Available"
            name="stockAvailable"
            rules={[{ required: false, message: 'required' }]}
            initialValue={get(product, 'stockAvailable', null)}
          >
            <InputNumber style={{ width: '200px' }} placeholder="Stock Available" min={0} step={1} />
          </FormItem>

          {(stockAvailable !== null && stockAvailable >= 0) && (
            <FormItem
              {...formItemLayout}
              label="0 Stock Action"
              name="convertTo"
              rules={[{ required: false, message: 'required' }]}
              initialValue={get(product, 'convertTo')}
              extra="Select an optional action when stock drops to 0"
            >
              <Select
                allowClear
                style={{ width: '200px' }}
              >
                <Option value={0}>Convert to In Store</Option>
                <Option value={1}>Convert to Non Stock</Option>
              </Select>
            </FormItem>
          )}

        </TabPane>

        <TabPane tab="Description" key="description" forceRender>
          <h2>Description</h2>

          <Editor
            name="description"
            editorState={product.description}
          />

          <br />
          <h2>Specification</h2>
          <Editor
            name="specification"
            editorState={product.specification}
          />

          <br />
          <h2>Warranty</h2>
          <FormItem
            name="warranty"
            rules={[{ required: false, message: 'required' }]}
            initialValue={product.warranty}
          >
            <Input placeholder="Warranty Info" />
          </FormItem>

          <Divider />

          <FormItem
            labelCol={{
              xs: { span: 24 },
              sm: { span: 6 }
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 18 }
            }}
            label="Meta Description"
            name="metaDescription"
            initialValue={product.metaDescription}
          >
            <TextArea rows={2} />
          </FormItem>

        </TabPane>

        {product.id && (
          <TabPane tab="Related" key="related" forceRender>
            <Related product={product} viewer={viewer} onImport={handleImport} />
          </TabPane>
        )}

        {product.id && (
          <TabPane tab="Alerts" key="alerts" forceRender>
            <Alert product={product} />
          </TabPane>
        )}

        <TabPane tab="Files" key="files" forceRender>
          <FormItem
            name="newFiles"
            rules={[{ required: false, message: 'required' }]}
          >
            <Upload
              multiple
              beforeUpload={() => false}
              listType="picture"
              fileList={state.fileList}
              onChange={(info) => {
                const fileList = info.fileList.filter((file) => {
                  const isLt15M = file.originFileObj.size / 1024 / 1024 < 15;
                  if (!isLt15M) {
                    message.error('Image must smaller than 15MB!');
                  }
                  return isLt15M;
                });

                setState((s) => ({ ...s, fileList }));
              }}
            >
              <Button>
                <UploadOutlined /> Upload
              </Button>
            </Upload>
          </FormItem>

          <List
            dataSource={get(product.files, 'edges', [])}
            renderItem={edge => (
              <List.Item
                key={edge.node.id}
                actions={[
                  <Popconfirm
                    key="delete-file"
                    title="Are you sure to delete this file?"
                    onConfirm={() => { deleteFile(edge.node); }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined title="Remove" style={{ opacity: 1 }} />
                  </Popconfirm>
                ]}
              >
                <a target="_blank" href={edge.node.url} rel="noopener noreferrer">{edge.node.name}</a>
              </List.Item>
            )}
          />
        </TabPane>

        {product.id && (
          <TabPane tab="History" key="history">
            <ProductHistory product={product} viewer={viewer} />
          </TabPane>
        )}
      </Tabs>
    </Form>
  )
};

ProductForm.propTypes = {
  viewer: PropTypes.shape({
    brands: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
    attributes: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
    categories: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
    subcategories: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  relay: PropTypes.shape({
    refetch: PropTypes.func.isRequired,
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.shape({}),
  deleteImage: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  })
};

ProductForm.defaultProps = {
  match: null,
  deleteImage: () => {},
  router: {
    push: () => {},
  },
};

export default createRefetchContainer(
  ProductForm, {
  viewer: graphql`
    fragment ProductForm_viewer on Admin @argumentDefinitions(
      query: {type: "String", defaultValue: ""},
      attributes: {type: "[InputAttributeList]"},
      subcategoryIds: {type: "[ID]"},
    ) {
      ...ProductHistory_viewer
      ...UrlRefresher_viewer
      ...Related_viewer
      ...WebsiteRef_viewer
      roles(first: 99){
        edges {
          node {
            name
          }
        }
      }
      attributes(first: 2000, subcategoryIds: $subcategoryIds, attributes: $attributes, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            code
            options
            required
            multi
          }
        }
      }
      categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            status
          }
        }
      }
      subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            status
          }
        }
      }
      brands(first: 1000, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      ...SelectProduct_viewer
    }
  `,
},
  graphql`
    query ProductFormRefetchQuery($subcategoryIds: [ID], $attributes: [InputAttributeList]) {
      viewer {
        attributes(first: 2000, subcategoryIds: $subcategoryIds, attributes: $attributes, orderBy: {field: "name", direction: "asc"}) {
          edges {
            node {
              id
              name
              code
              options
              required
              multi
            }
          }
        }
      }
    }
  `,
);
