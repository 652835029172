/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProductForm_viewer$ref = any;
type ProductHistory_product$ref = any;
type Related_product$ref = any;
type Sorting_product$ref = any;
type WebsiteRef_product$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProductView_viewer$ref: FragmentReference;
declare export opaque type ProductView_viewer$fragmentType: ProductView_viewer$ref;
export type ProductView_viewer = {|
  +products: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +attributes: ?any,
        +alerts: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +message: ?string,
              +startDate: ?string,
              +endDate: ?string,
              +status: ?boolean,
            |}
          |}>
        |},
        +metaDescription: ?string,
        +brandId: ?string,
        +partialName: ?string,
        +name: ?string,
        +model: ?string,
        +adenCode: ?string,
        +odooCode: ?string,
        +secondModel: ?string,
        +barcode: ?string,
        +visibility: ?number,
        +sku: ?string,
        +urlSlug: ?string,
        +type: ?string,
        +finalPrice: ?number,
        +callForPrice: ?boolean,
        +priceDrop: ?{|
          +enable: ?boolean,
          +startDate: ?string,
          +endDate: ?string,
        |},
        +inStoreOnly: ?boolean,
        +onlineOnly: ?boolean,
        +nonStock: ?boolean,
        +costPrice: ?number,
        +price: ?number,
        +updatePriceSchedules: ?$ReadOnlyArray<?{|
          +id: ?string,
          +newPrice: ?number,
          +effectiveDate: ?string,
        |}>,
        +tierPrices: ?any,
        +specialPrice: ?number,
        +specialPriceStartDate: ?string,
        +specialPriceEndDate: ?string,
        +flyerPrice: ?number,
        +flyerPriceStartDate: ?string,
        +flyerPriceEndDate: ?string,
        +videos: ?$ReadOnlyArray<?string>,
        +promoCard: ?boolean,
        +description: ?string,
        +specification: ?string,
        +warranty: ?string,
        +weight: ?number,
        +width: ?number,
        +length: ?number,
        +height: ?number,
        +bulkyGood: ?boolean,
        +backInStockDate: ?string,
        +preorderDate: ?string,
        +hideStockDate: ?boolean,
        +stockAvailable: ?number,
        +convertTo: ?number,
        +freeShipping: ?boolean,
        +freeShippingStartDate: ?string,
        +freeShippingEndDate: ?string,
        +hideGlobalFreeShipping: ?boolean,
        +excludeGlobalFreeShipping: ?boolean,
        +excludeExpressShipping: ?boolean,
        +dangerousGood: ?boolean,
        +status: ?string,
        +enableStartDate: ?string,
        +enableEndDate: ?string,
        +mainImage: ?{|
          +id: string,
          +url: ?string,
        |},
        +feedImage: ?{|
          +id: string,
          +url: ?string,
        |},
        +files: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +url: ?string,
              +name: ?string,
            |}
          |}>
        |},
        +images: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +url: ?string,
              +position: ?number,
              +showInKit: ?boolean,
              +status: ?boolean,
            |}
          |}>
        |},
        +bundles: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +product: ?{|
                +id: string,
                +name: ?string,
                +sku: ?string,
                +status: ?string,
                +sellable: ?boolean,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                |},
              |},
              +position: ?number,
              +quantity: ?number,
            |}
          |}>
        |},
        +configurableAttributes: ?$ReadOnlyArray<?{|
          +id: string,
          +code: ?string,
          +name: ?string,
        |}>,
        +configurables: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +position: ?number,
              +product: ?{|
                +id: string,
                +name: ?string,
                +type: ?string,
                +attributes: ?any,
                +visibility: ?number,
                +sku: ?string,
                +status: ?string,
                +sellable: ?boolean,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                |},
              |},
            |}
          |}>
        |},
        +categoryIds: ?$ReadOnlyArray<?string>,
        +subcategoryIds: ?$ReadOnlyArray<?string>,
        +linkOnlyDays: ?number,
        +searchScoreMultiplier: ?number,
        +websiteReference: ?{|
          +id: ?string,
          +country: ?string,
        |},
        +$fragmentRefs: ProductHistory_product$ref & Related_product$ref & Sorting_product$ref & WebsiteRef_product$ref,
      |}
    |}>
  |},
  +$fragmentRefs: ProductForm_viewer$ref,
  +$refType: ProductView_viewer$ref,
|};
export type ProductView_viewer$data = ProductView_viewer;
export type ProductView_viewer$key = {
  +$data?: ProductView_viewer$data,
  +$fragmentRefs: ProductView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attributes",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v13 = [
  (v0/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sellable",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    },
    {
      "kind": "RootArgument",
      "name": "skip"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductView_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "ProductConnection",
      "kind": "LinkedField",
      "name": "products",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": "alerts",
                  "args": null,
                  "concreteType": "ProductAlertConnection",
                  "kind": "LinkedField",
                  "name": "__ProductView_alerts_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductAlertEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductAlert",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "message",
                              "storageKey": null
                            },
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "metaDescription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "brandId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partialName",
                  "storageKey": null
                },
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "model",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "adenCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "odooCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "secondModel",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "barcode",
                  "storageKey": null
                },
                (v9/*: any*/),
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "urlSlug",
                  "storageKey": null
                },
                (v11/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "finalPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "callForPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceDrop",
                  "kind": "LinkedField",
                  "name": "priceDrop",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "enable",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "inStoreOnly",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "onlineOnly",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nonStock",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "costPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UpdatePriceSchedule",
                  "kind": "LinkedField",
                  "name": "updatePriceSchedules",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "newPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "effectiveDate",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tierPrices",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "specialPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "specialPriceStartDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "specialPriceEndDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "flyerPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "flyerPriceStartDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "flyerPriceEndDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "videos",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "promoCard",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "specification",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "warranty",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "weight",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "width",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "length",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "height",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bulkyGood",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "backInStockDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "preorderDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hideStockDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stockAvailable",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "convertTo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "freeShipping",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "freeShippingStartDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "freeShippingEndDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hideGlobalFreeShipping",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "excludeGlobalFreeShipping",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "excludeExpressShipping",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dangerousGood",
                  "storageKey": null
                },
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "enableStartDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "enableEndDate",
                  "storageKey": null
                },
                (v14/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductImage",
                  "kind": "LinkedField",
                  "name": "feedImage",
                  "plural": false,
                  "selections": (v13/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": "files",
                  "args": null,
                  "concreteType": "ProductFileConnection",
                  "kind": "LinkedField",
                  "name": "__ProductView_files_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductFileEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductFile",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v12/*: any*/),
                            (v8/*: any*/),
                            (v5/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": "images",
                  "args": null,
                  "concreteType": "ProductImageConnection",
                  "kind": "LinkedField",
                  "name": "__ProductView_images_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductImageEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductImage",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v12/*: any*/),
                            (v15/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "showInKit",
                              "storageKey": null
                            },
                            (v4/*: any*/),
                            (v5/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v16/*: any*/),
                  "concreteType": "ProductBundleConnection",
                  "kind": "LinkedField",
                  "name": "bundles",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductBundleEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductBundle",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Product",
                              "kind": "LinkedField",
                              "name": "product",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v8/*: any*/),
                                (v10/*: any*/),
                                (v4/*: any*/),
                                (v17/*: any*/),
                                (v14/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v15/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "quantity",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "bundles(first:99)"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Attribute",
                  "kind": "LinkedField",
                  "name": "configurableAttributes",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "code",
                      "storageKey": null
                    },
                    (v8/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v16/*: any*/),
                  "concreteType": "ProductConfigurableConnection",
                  "kind": "LinkedField",
                  "name": "configurables",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductConfigurableEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductConfigurable",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v15/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Product",
                              "kind": "LinkedField",
                              "name": "product",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v8/*: any*/),
                                (v11/*: any*/),
                                (v1/*: any*/),
                                (v9/*: any*/),
                                (v10/*: any*/),
                                (v4/*: any*/),
                                (v17/*: any*/),
                                (v14/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "configurables(first:99)"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "categoryIds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subcategoryIds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "linkOnlyDays",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "searchScoreMultiplier",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebsiteReference",
                  "kind": "LinkedField",
                  "name": "websiteReference",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "country",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "condition": "skip",
                  "kind": "Condition",
                  "passingValue": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProductHistory_product"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Related_product"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Sorting_product"
                    }
                  ]
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "WebsiteRef_product"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '38efbf36d1b2ac55cf8d608a64bf8deb';

module.exports = node;
