/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateStateManagerInput = {|
  emails?: ?$ReadOnlyArray<?string>,
  id?: ?string,
  state: string,
|};
export type UpdateStateManagerMutationVariables = {|
  input: UpdateStateManagerInput
|};
export type UpdateStateManagerMutationResponse = {|
  +updateStateManager: ?{|
    +stateManager: ?{|
      +emails: ?$ReadOnlyArray<?string>,
      +state: ?string,
    |}
  |}
|};
export type UpdateStateManagerMutation = {|
  variables: UpdateStateManagerMutationVariables,
  response: UpdateStateManagerMutationResponse,
|};
*/


/*
mutation UpdateStateManagerMutation(
  $input: UpdateStateManagerInput!
) {
  updateStateManager(input: $input) {
    stateManager {
      emails
      state
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emails",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateStateManagerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStateManagerPayload",
        "kind": "LinkedField",
        "name": "updateStateManager",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StateManager",
            "kind": "LinkedField",
            "name": "stateManager",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateStateManagerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStateManagerPayload",
        "kind": "LinkedField",
        "name": "updateStateManager",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StateManager",
            "kind": "LinkedField",
            "name": "stateManager",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ab91b68070d5ee13c61849c53b99586",
    "id": null,
    "metadata": {},
    "name": "UpdateStateManagerMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateStateManagerMutation(\n  $input: UpdateStateManagerInput!\n) {\n  updateStateManager(input: $input) {\n    stateManager {\n      emails\n      state\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd1c1338b659e7688668c6c8c8be8b74c';

module.exports = node;
