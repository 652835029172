/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAdminUserInput = {|
  firstname?: ?string,
  id?: ?string,
  lastname?: ?string,
  password?: ?string,
  roles?: ?$ReadOnlyArray<?string>,
  status?: ?boolean,
  username?: ?string,
|};
export type UpdateAdminUserMutationVariables = {|
  input: UpdateAdminUserInput
|};
export type UpdateAdminUserMutationResponse = {|
  +updateAdminUser: ?{|
    +adminUser: ?{|
      +username: ?string,
      +firstname: ?string,
      +lastname: ?string,
      +status: ?boolean,
      +roles: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
      |}>,
    |}
  |}
|};
export type UpdateAdminUserMutation = {|
  variables: UpdateAdminUserMutationVariables,
  response: UpdateAdminUserMutationResponse,
|};
*/


/*
mutation UpdateAdminUserMutation(
  $input: UpdateAdminUserInput!
) {
  updateAdminUser(input: $input) {
    adminUser {
      username
      firstname
      lastname
      status
      roles {
        id
        name
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AdminRole",
  "kind": "LinkedField",
  "name": "roles",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAdminUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdminUserPayload",
        "kind": "LinkedField",
        "name": "updateAdminUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminUser",
            "kind": "LinkedField",
            "name": "adminUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAdminUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdminUserPayload",
        "kind": "LinkedField",
        "name": "updateAdminUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminUser",
            "kind": "LinkedField",
            "name": "adminUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a2b7451e65febd8d1aaa453b6f22a94a",
    "id": null,
    "metadata": {},
    "name": "UpdateAdminUserMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAdminUserMutation(\n  $input: UpdateAdminUserInput!\n) {\n  updateAdminUser(input: $input) {\n    adminUser {\n      username\n      firstname\n      lastname\n      status\n      roles {\n        id\n        name\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7c9b2bc3e6fe773fbff14e4c5641fe5d';

module.exports = node;
