/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CategoryHistory_category$ref = any;
type WebsiteRef_category$ref = any;
export type UpdateCategoryInput = {|
  aliases?: ?$ReadOnlyArray<?string>,
  brands?: ?$ReadOnlyArray<?InputCategoryBrand>,
  description?: ?string,
  description2?: ?string,
  displayMode?: ?string,
  enableFromTo?: ?$ReadOnlyArray<?string>,
  featured?: ?boolean,
  id?: ?string,
  includeInFilter?: ?boolean,
  includeInMenu?: ?boolean,
  includeInSearch?: ?boolean,
  includeInSitemap?: ?boolean,
  internalPriceMatch?: ?boolean,
  jsonLd?: ?string,
  metaDescription?: ?string,
  metaTitle?: ?string,
  name?: ?string,
  position?: ?number,
  previewDescription?: ?string,
  scheduleAssignments?: ?$ReadOnlyArray<?InputCategorySchedule>,
  scheduleRemovals?: ?$ReadOnlyArray<?InputCategorySchedule>,
  status?: ?boolean,
  urlSlug?: ?string,
  wideImage?: ?boolean,
|};
export type InputCategoryBrand = {|
  id: string,
  image?: ?any,
|};
export type InputCategorySchedule = {|
  datetime?: ?string,
  products?: ?$ReadOnlyArray<?InputCategoryScheduleProduct>,
|};
export type InputCategoryScheduleProduct = {|
  productId?: ?string
|};
export type UpdateCategoryMutationVariables = {|
  input: UpdateCategoryInput
|};
export type UpdateCategoryMutationResponse = {|
  +updateCategory: ?{|
    +category: ?{|
      +name: ?string,
      +urlSlug: ?string,
      +description: ?string,
      +description2: ?string,
      +includeInMenu: ?boolean,
      +includeInSearch: ?boolean,
      +includeInSitemap: ?boolean,
      +includeInFilter: ?boolean,
      +internalPriceMatch: ?boolean,
      +featured: ?boolean,
      +position: ?number,
      +aliases: ?$ReadOnlyArray<?string>,
      +displayMode: ?string,
      +brands: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +imageUrl: ?string,
          |}
        |}>
      |},
      +scheduleAssignments: ?$ReadOnlyArray<?{|
        +id: string,
        +datetime: ?string,
        +products: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
      |}>,
      +scheduleRemovals: ?$ReadOnlyArray<?{|
        +id: string,
        +datetime: ?string,
        +products: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
              |},
            |}
          |}>
        |},
      |}>,
      +status: ?boolean,
      +enableStartDate: ?string,
      +enableEndDate: ?string,
      +previewDescription: ?string,
      +metaTitle: ?string,
      +metaDescription: ?string,
      +wideImage: ?boolean,
      +jsonLd: ?string,
      +$fragmentRefs: CategoryHistory_category$ref & WebsiteRef_category$ref,
    |}
  |}
|};
export type UpdateCategoryMutation = {|
  variables: UpdateCategoryMutationVariables,
  response: UpdateCategoryMutationResponse,
|};
*/


/*
mutation UpdateCategoryMutation(
  $input: UpdateCategoryInput!
) {
  updateCategory(input: $input) {
    category {
      name
      urlSlug
      description
      description2
      includeInMenu
      includeInSearch
      includeInSitemap
      includeInFilter
      internalPriceMatch
      featured
      position
      aliases
      displayMode
      brands(first: 9999) {
        edges {
          node {
            id
            name
            imageUrl
          }
        }
      }
      scheduleAssignments {
        id
        datetime
        products(first: 9999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
      }
      scheduleRemovals {
        id
        datetime
        products(first: 9999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
      }
      status
      enableStartDate
      enableEndDate
      previewDescription
      metaTitle
      metaDescription
      wideImage
      jsonLd
      ...CategoryHistory_category
      ...WebsiteRef_category
      id
    }
  }
}

fragment CategoryHistory_category on Category {
  id
  histories(first: 10) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        changes
        user
        insertedAt
        __typename
      }
      cursor
    }
  }
}

fragment WebsiteRef_category on Category {
  websiteReference {
    id
    country
  }
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlSlug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description2",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "includeInMenu",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "includeInSearch",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "includeInSitemap",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "includeInFilter",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalPriceMatch",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "featured",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aliases",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayMode",
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": (v15/*: any*/),
  "concreteType": "BrandConnection",
  "kind": "LinkedField",
  "name": "brands",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Brand",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v16/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "brands(first:9999)"
},
v18 = [
  (v16/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "datetime",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v15/*: any*/),
    "concreteType": "ProductConnection",
    "kind": "LinkedField",
    "name": "products",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductImage",
                "kind": "LinkedField",
                "name": "mainImage",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "products(first:9999)"
  }
],
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "CategoryScheduleAssignments",
  "kind": "LinkedField",
  "name": "scheduleAssignments",
  "plural": true,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "CategoryScheduleRemovals",
  "kind": "LinkedField",
  "name": "scheduleRemovals",
  "plural": true,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enableStartDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enableEndDate",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "previewDescription",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaTitle",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaDescription",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wideImage",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonLd",
  "storageKey": null
},
v29 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCategoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCategoryPayload",
        "kind": "LinkedField",
        "name": "updateCategory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Category",
            "kind": "LinkedField",
            "name": "category",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v17/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CategoryHistory_category"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WebsiteRef_category"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCategoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCategoryPayload",
        "kind": "LinkedField",
        "name": "updateCategory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Category",
            "kind": "LinkedField",
            "name": "category",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v17/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": (v29/*: any*/),
                "concreteType": "HistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "History",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "user",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "histories(first:10)"
              },
              {
                "alias": null,
                "args": (v29/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "CategoryHistory_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WebsiteReference",
                "kind": "LinkedField",
                "name": "websiteReference",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "502efae35ae1c75a239212ebbce1079f",
    "id": null,
    "metadata": {},
    "name": "UpdateCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCategoryMutation(\n  $input: UpdateCategoryInput!\n) {\n  updateCategory(input: $input) {\n    category {\n      name\n      urlSlug\n      description\n      description2\n      includeInMenu\n      includeInSearch\n      includeInSitemap\n      includeInFilter\n      internalPriceMatch\n      featured\n      position\n      aliases\n      displayMode\n      brands(first: 9999) {\n        edges {\n          node {\n            id\n            name\n            imageUrl\n          }\n        }\n      }\n      scheduleAssignments {\n        id\n        datetime\n        products(first: 9999) {\n          edges {\n            node {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n      }\n      scheduleRemovals {\n        id\n        datetime\n        products(first: 9999) {\n          edges {\n            node {\n              id\n              name\n              mainImage {\n                id\n                url\n              }\n            }\n          }\n        }\n      }\n      status\n      enableStartDate\n      enableEndDate\n      previewDescription\n      metaTitle\n      metaDescription\n      wideImage\n      jsonLd\n      ...CategoryHistory_category\n      ...WebsiteRef_category\n      id\n    }\n  }\n}\n\nfragment CategoryHistory_category on Category {\n  id\n  histories(first: 10) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        changes\n        user\n        insertedAt\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment WebsiteRef_category on Category {\n  websiteReference {\n    id\n    country\n  }\n  id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f2e616a110b4720ee7a9bbe04324a93b';

module.exports = node;
