import React from 'react';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { get, isEmpty, isEqual, unionBy } from 'lodash';
import { Button, Form, message, Select, Slider, Upload } from 'antd';
import { UploadOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { fromGlobalId, genCsv, toGlobalId } from '../../helper';

const { Option } = Select;

const styles = {
  options: {
    opacity: 0.5
  }
};

class SelectProduct extends React.Component {

  static propTypes = {
    viewer: PropTypes.shape({
      searchProducts: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      searchProductsByPrice: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    importExport: PropTypes.shape({
      onImport: PropTypes.func,
      listToExport: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            mainImage: PropTypes.shape({
              id: PropTypes.string,
              url: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
    filters: PropTypes.shape({
      brand: PropTypes.string,
      brands: PropTypes.arrayOf(PropTypes.string),
    }),
  }

  static defaultProps = {
    filters: {},
    importExport: {
      onImport: null,
      listToExport: []
    },
  }

  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      sliderValue: [0, 0],
    };
  }

  componentDidUpdate = (prevProps) => {
    const { filters } = this.props;
    const { sliderValue } = this.state;

    if (Object.prototype.hasOwnProperty.call(filters, 'brand')) {
      const oldValue = get(prevProps, 'filters.brand');
      const newValue = get(this.props, 'filters.brand');

      if (oldValue !== newValue) {
        this.fetchProductFilter(fromGlobalId(newValue), sliderValue);
      }
    } else if (Object.prototype.hasOwnProperty.call(filters, 'brands')) {
      const oldValue = get(prevProps, 'filters.brands', []);
      const newValue = get(this.props, 'filters.brands', []);
      const filterBrand = get(newValue, '[0]', "");

      if (filterBrand !== '*' && newValue.length === 1 && !isEqual(oldValue, newValue)) {
        this.fetchProductFilter(fromGlobalId(filterBrand), sliderValue);
      }
    }
  }

  getFilterBrand = (filters) => {
    if (Object.prototype.hasOwnProperty.call(filters, 'brand')) {
      return get(filters, 'brand', "");
    } else if (Object.prototype.hasOwnProperty.call(filters, 'brands')) {
      const filterBrands = get(filters, 'brands', []);
      return filterBrands.length === 1 ? get(filterBrands, '[0]', "") : "";
    }

    return "";
  }

  sliderOnChange = value => {
    this.setState({
      sliderValue: value,
    });
  }

  fetchProductFilter = (brand, sliderValue) => {
    if (brand && sliderValue) {
      const filterBy = [{
        field: "brand_id",
        filter: brand.id,
        filterType: "number",
        type: "equals",
      }];

      if (sliderValue) {
        filterBy.push({
          field: "price",
          filter: String(sliderValue),
          filterType: "text",
          type: "inRange",
        });
      }
      this.props.relay.refetch({ first: 9999, query: null, orderBy: { field: "price", direction: "asc" }, filterBy });
    }
  }

  handleClick = () => {
    const { listToExport } = this.props.importExport;
    const csvHeaders = ['id', 'name'];

    const transformedList = listToExport.map(({ node }) => {
      const { id: globalId, name } = node;
      const localId = fromGlobalId(globalId).id;

      return {
        id: localId,
        name,
      };
    });

    if (transformedList.length > 0) {
      genCsv(
        transformedList,
        csvHeaders,
        (timeNow) => `Export-Products-List-${timeNow}`
      );
    }
  }

  handleBeforeUpload = (file) => {
    Papa.parse(file, {
      complete: (result) => {
        if (result.meta.fields.includes('id')) {
          this.processUploadedFile(result.data);
        } else {
          message.error("No 'id' in the file", 5);
        }
      },
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true
    });

    return false;
  };

  processUploadedFile = (products) => {
    const { onImport } = this.props.importExport;

    try {
      if (products.length > 1000) {
        throw new Error('Import file must not contain more than 1000 products');
      }
      if (products.length === 0) {
        throw new Error('Import file must not be empty');
      }

      const invalidProduct = products.find(product => !Number.isInteger(product.id) || product.id < 1);
      if (invalidProduct) {
        throw new Error(`Invalid product id: ${invalidProduct.id}`);
      }

      const options = products
        .map(product => ({
          key: toGlobalId("Product", product.id),
          label: product.name || product.id,
        }));

      if (typeof onImport === 'function' && options.length > 0) {
        onImport(options);
      }
    } catch (error) {
      message.error(error.message, 5);
    }
  };

  fetchProduct = (value) => {
    this.props.relay.refetch({ first: 20, query: value, orderBy: null, filterBy: null });
  }

  renderOption = (p) => (
    <div product={p}>
      <img width="20" height="20" src={p.mainImage && p.mainImage.url} alt="" />
      {p.name}
    </div>
  )

  render() {
    const { viewer, filters, importExport: { onImport, listToExport }, ...selectProps } = this.props;
    const searchProducts = get(viewer, 'searchProducts.edges', []);
    const filterBrand = this.getFilterBrand(filters);

    return (
      <div>
        <Select
          allowClear
          onSearch={this.fetchProduct}
          {...selectProps}
        >
          {searchProducts.map((d) => {
            const p = d.node;
            return (
              <Option
                key={p.id}
                title={`${p.name}`}
                style={p.status === 'enabled' ? {} : styles.options}
              >
                {this.renderOption(p)}
              </Option>
            );
          })}
        </Select>

        {onImport && (
          <div style={{ color: 'grey' }}>
            {listToExport.length} selected
          </div>
        )}

        {onImport && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Button onClick={this.handleClick} style={{ marginTop: '10px', width: '100px'  }}>
              <VerticalAlignBottomOutlined />Export
            </Button>
            <Upload
              className="importProduct"
              accept=".csv"
              maxCount={1}
              ref={this.ref}
              beforeUpload={this.handleBeforeUpload}
              showUploadList={false}
            >
              <Button style={{ width: '100px' }}>
                <UploadOutlined /> Import
              </Button>
            </Upload>
          </div>
        )}

        {(filterBrand && filterBrand !== '*') && (
          <React.Fragment>
            <Slider
              range
              step={1}
              min={0}
              max={9999}
              marks={{ 0: '$0', 9999: { style: { transform: 'translateX(-100%)' }, label: '$9999' } }}
              disabled={isEmpty(filterBrand)}
              onAfterChange={(value) => {
                this.sliderOnChange(value);
                this.fetchProductFilter(fromGlobalId(filterBrand), value);
              }}
            />
            <Form.Item
              validateStatus="warning"
              help="* Search will be only limited to first 1000 results"
            >
              <Button
                title="Apply Exclude Price on items"
                disabled={isEmpty(filterBrand)}
                onClick={() => {
                  const searchProductResult = searchProducts.map(items => {
                    const item = items.node;
                    return ({
                      key: item.id,
                      label: this.renderOption(item)
                    })
                  });
                  if (typeof onImport === 'function') {
                    onImport(searchProductResult);
                  }
                }}
              >
                Apply Search Price Range
              </Button>
            </Form.Item>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const Hoc = createRefetchContainer(
  SelectProduct, {
  viewer: graphql`
    fragment SelectProduct_viewer on Admin @argumentDefinitions(
      first: {type: "Int", defaultValue: 20},
      query: {type: "String", defaultValue: ""},
      filterBy: {type: "[FilterBy]", defaultValue: null},
      orderBy: {type: OrderBy, defaultValue: null},
    ) {
      searchProducts: products(first: $first, query: $query, orderBy: $orderBy, filterBy: $filterBy) {
        edges {
          node {
            id
            name
            type
            attributes
            sku
            status
            mainImage {
              id
              url
            }
            configurables(first: 99) {
              edges {
                node {
                  id
                  product {
                    id
                    name
                    sku
                    status
                    mainImage {
                      id
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
},
  graphql`
  query SelectProductRefetchQuery($first: Int, $query: String, $orderBy: OrderBy, $filterBy: [FilterBy]) {
    viewer {
      searchProducts: products(first: $first, query: $query, orderBy: $orderBy, filterBy: $filterBy) {
        edges {
          node {
            id
            name
            type
            attributes
            sku
            status
            mainImage {
              id
              url
            }
            configurables(first: 99) {
              edges {
                node {
                  id
                  product {
                    id
                    name
                    sku
                    status
                    mainImage {
                      id
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`,
);

Hoc.updateSelect = (form, value, field) => {
  if (value && value.length > 0) {
    const { getFieldValue, setFieldsValue } = form;
    const newCurrExcludeProd = unionBy(getFieldValue(field), value, 'key');
    setFieldsValue({
      [field]: newCurrExcludeProd,
    });
  }
};

Hoc.productOptions = (product) => {
  if (!product) {
    return null;
  }
  return {
    key: product.id,
    label: (
      <div>
        <img width="20" height="20" src={get(product, 'mainImage.url', '')} alt="" />
        {get(product, 'name', '')}
      </div>
    ),
  };
}

export default Hoc;
