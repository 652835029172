import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateCreditMutation($input: UpdateCreditInput!) {
    updateCredit(input:$input) {
      credit {
        fromBrandIds
        name
        tiers(first: 999) {
          edges {
            node {
              id
              price
              creditAmount
            }
          }
        }
        promoItems(first: 999) {
          edges {
            node {
              id
              name
              status
            }
          }
        }
        creditFromProducts(first: 999) {
          edges {
            node {
              id
              product {
                id
                name
                mainImage {
                  id
                  url
                }
              }
              creditAmount
            }
          }
        }
        percentage
        minSpent
        applyBrandIds
        applyCreditMode
        excludeBrandIds
        excludeProducts(first: 9999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        excludeProductsSpend(first: 9999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        includeProducts(first: 9999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        startDate
        endDate
        expiry
        stealthMode
        excludeNonStock
        includeNonStockBrands {
          id
          name
        }
        excludePriceDrop
        status
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
