import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Affix, Button, Form, Input, Select } from 'antd';
import { FormBase, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';

const { Item: FormItem } = Form;
const { Option }= Select;

class UserForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      adminUsers: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      adminRoles: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match, viewer } = this.props;
    const user = get(viewer, 'adminUsers.edges[0].node', {});
    const roles = get(viewer, 'adminRoles.edges', []);

    return (
      <Form ref={this.formRef} onFinish={(values)=>{this.props.onSubmit(this.formRef.current, values);}}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        <FormItem
          name="id"
          initialValue={user.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="User Name"
          name="username"
          rules={[{ required: true, message: 'required' }]}
          initialValue={user.username}
        >
          <Input placeholder="User Name" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="First Name"
          name="firstname"
          rules={[{ required: true, message: 'required' }]}
          initialValue={user.firstname}
        >
          <Input placeholder="First Name" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Last Name"
          name="lastname"
          rules={[{ required: true, message: 'required' }]}
          initialValue={user.lastname}
        >
          <Input placeholder="Last Name" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Password"
          name="password"
          rules={[{ required: !user.id, message: 'required' }]}
        >
          <Input placeholder="password" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Status"
          name="status"
          rules={[{ required: true, message: 'required' }]}
          initialValue={get(user, 'status') ? 1 : 0}
        >
          <Select placeholder="Status">
            <Option value={1}>Enabled</Option>
            <Option value={0}>Disabled</Option>
          </Select>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Roles"
          name="roles"
          initialValue={get(user, 'roles', []).map(p => ({
            key: p.id,
            label: p.name,
          }))}
        >
          <Select
            placeholder="Select Roles"
            labelInValue
            mode="multiple"
            optionFilterProp="children"
          >
            {roles.map((edge) => {
              const r = edge.node;
              return <Option key={r.id} value={r.id}>{r.name}</Option>;
            })}
          </Select>
        </FormItem>

      </Form>
    );
  }
}
export default UserForm;
