import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Button, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ImportProductsMutation } from './mutations';

const headers = [[
  'partial_name',
  'model',
  'aden_code',
  'barcode',
  'brand',
  'price',
  'width',
  'height',
  'length',
  'weight',
  'categories',
  'subcategories',
]];

const csvContent = `data:text/csv;charset=utf-8,${ headers.map(e => e.join(",")).join("\n") }`;

const encodedUri = encodeURI(csvContent);

class ImportProducts extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  handleSubmit = () => {
    const { fileList } = this.ref.current;

    if (fileList.length > 1) {
      message.error('One import file at a time');
      return;
    }

    if (fileList.length === 0) {
      message.error('Please upload a import file.');
      return;
    }

    const file = fileList[0].originFileObj;
    const name = 'product0';

    const values = {
      products: name,
    };

    const uploadables = {
      [name]: file
    };

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      uploadables,
      onCompleted: (resp) => {
        if (resp.importProducts) {
          window.location.reload();
        }
      }
    };

    ImportProductsMutation.commit(mutation);
  }

  render() {
    return (
      <div style={{display: 'inline-block'}}>
        <a href={encodedUri} download="template.csv" style={{margin: '0 10px'}} >Template</a>

        <Upload
          className="importProduct"
          accept=".csv"
          beforeUpload={() => false}
          listType="picture"
          maxCount={1}
          ref={this.ref}
        >
          <Button>
            <UploadOutlined /> Upload
          </Button>
        </Upload>

        <Button
          style={{marginLeft: '10px'}}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </div>
    );
  }
}

export default createFragmentContainer(ImportProducts, {
  viewer: graphql`
    fragment ImportProducts_viewer on Admin {
      id
    }
  `,
});
