import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { Form, InputNumber, Switch, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { formItemLayout } from '~/components/form';

const { Item: FormItem } = Form;
const HALF_TIME = 6; // months
const HALF_TIME_UNIT = "MONTH"; // "DAY", "WEEK", "MONTH", "YEAR"
const SUM_SALES_PERIOD = 180; // days

const Sorting = (props) => {
  const { product } = props;

  const form = Form.useFormInstance();

  /* Refetch dynamic sorting factor */
  const doRefetch = (dynamic, value) => {
    if (product !== null && dynamic && typeof(value) === 'number') {
      props.relay.refetch({
        ids: [product.id],
        value,
      });
    }
  }

  const getDynamicFactor = (value) => {
    const dynamic = form.getFieldValue('enableDynamicSorting');
    doRefetch(dynamic, value);
  }

  const toggleDynamic = (dynamic) => {
    const value = form.getFieldValue('sortingFactor');
    doRefetch(dynamic, value);
  }

  return (
    <div>
      <FormItem
        {...formItemLayout}
        label="Sorting Factor"
        name="sortingFactor"
        rules={[{ required: false, message: 'required' }]}
        initialValue={get(product, 'sortingFactor', 0)}
        extra="For category/subcategory pages"
      >
        <InputNumber placeholder="Sorting Factor" onChange={getDynamicFactor} />
      </FormItem>

      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => getFieldValue('enableDynamicSorting') === true && (props.product !== null) && (
          <FormItem
            {...formItemLayout}
            label="Dynamic Sorting Factor"
          >
            {getFieldValue('sortingFactor') && product.sorting}
          </FormItem>
        )}
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="Enable Dynamic Sorting"
      >
        <FormItem
          name="enableDynamicSorting"
          valuePropName="checked"
          initialValue={get(product, 'enableDynamicSorting', false)}
          style={{'display': 'inline-block'}}
        >
          <Switch onChange={toggleDynamic} />
        </FormItem>

        <Tooltip title={`When enabled, Sorting Factor will decay slowly with time (be halved after ${HALF_TIME} ${HALF_TIME_UNIT} (${HALF_TIME} ${HALF_TIME_UNIT} half-life)), and increases with number of sales in the last ${SUM_SALES_PERIOD} days.`}>
          <QuestionCircleOutlined style={{marginLeft: '5px'}} />
        </Tooltip>
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="Search Score Multiplier"
        name="searchScoreMultiplier"
        initialValue={get(product, 'searchScoreMultiplier', 1.0)}
        extra="1.1 means 10% increase in scoring. For search page only. Don't be crazy!"
      >
        <InputNumber />
      </FormItem>
    </div>
  );
}

Sorting.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    enableDynamicSorting: PropTypes.bool,
    sorting: PropTypes.number,
    sortingFactor: PropTypes.number,
    searchScoreMultiplier: PropTypes.number,
  }),
  relay: PropTypes.shape({
    refetch: PropTypes.func.isRequired,
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
};

Sorting.defaultProps = {
  product: null,
};

export default createRefetchContainer(
  Sorting, {
    product: graphql`
    fragment Sorting_product on Product @argumentDefinitions(
      value: {type: "Float", defaultValue: null}
    ) {
        id
        enableDynamicSorting
        sortingFactor
        searchScoreMultiplier
        sorting: sortingFactor(value: $value, dynamic: true)
    }
  `,
  },

  graphql`
    query SortingRefetchQuery($ids: [ID!], $value: Float) {
      viewer {
        products(first: 1, ids: $ids) {
          edges {
            node {
              sorting: sortingFactor(value: $value, dynamic: true)
              searchScoreMultiplier
            }
          }
        }
      }
    }
  `,
);
