import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Button, Select } from 'antd';

import { getNodeIds } from '~/helper';
import { AssignProductsToCategoriesMutation } from './mutations';

const { Option } = Select;

class AssignProductsToCategories extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    gridApi: PropTypes.shape({
      getSelectedRows: PropTypes.func.isRequired,
    }).isRequired,
  }

  stats = {
    value: null,
  }

  handleChange = (value) => {
    this.setState({
      value
    });
  }

  handleSubmit = () => {
    const { value: categoryIds } = this.state;
    const productIds = getNodeIds(this.props.gridApi.getSelectedRows());

    if (categoryIds.length && productIds.length) {
      AssignProductsToCategoriesMutation.commit({
        environment: this.props.relay.environment,
        variables: { input: { productIds, categoryIds } },
        viewer: this.props.viewer,
      });
    }
  }

  render() {

    return (
      <div style={{display: 'inline-block'}}>
        <Select
          key="categories"
          placeholder="Categories"
          mode="multiple"
          optionFilterProp="children"
          onChange={this.handleChange}
          style={{ width: '200px' }}
        >
          {
            this.props.viewer.categories.edges.map((edge) => {
              const e = edge.node;
              return <Option key={e.id}>{e.name}</Option>;
            })
          }
        </Select>

        <Button
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </div>
    );
  }
};

export default createFragmentContainer(AssignProductsToCategories, {
  viewer: graphql`
    fragment AssignProductsToCategories_viewer on Admin {
      categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
