import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Affix, Button, Form, Input, Select } from 'antd';

import { FormBase, formItemLayout, getStates } from '~/components/form';
import Presence from '~/components/Presence';
import { checkEmails } from '~/helper';

const { Item: FormItem } = Form;

class StateManagerForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      stateManagers: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: {},
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match, viewer } = this.props;
    const stateManager = get(viewer, 'stateManagers.edges[0].node', {});

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        <FormItem
          name="id"
          initialValue={stateManager.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Emails"
          name="emails"
          rules={[
            { required: true, message: 'required' },
            { required: true, validator: checkEmails }
          ]}
          initialValue={get(stateManager, 'emails', undefined) || undefined}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="State Managers"
          />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="State"
          name="state"
          rules={[{ required: true, message: 'required' }]}
          initialValue={get(stateManager, 'state', null)}
        >
          <Select
            placeholder="State"
            options={Object.entries(getStates()).map(([k, v]) => ({ value: k, label: v }))}
          />
        </FormItem>
      </Form>
    )
  }
}
export default StateManagerForm;
