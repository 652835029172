/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type App_viewer$ref = any;
export type routes_App_QueryVariables = {||};
export type routes_App_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: App_viewer$ref
  |}
|};
export type routes_App_Query = {|
  variables: routes_App_QueryVariables,
  response: routes_App_QueryResponse,
|};
*/


/*
query routes_App_Query {
  viewer {
    ...App_viewer
    id
  }
}

fragment App_viewer on Admin {
  id
  username
  roles(first: 99) {
    edges {
      node {
        name
        id
      }
    }
  }
  ...Logout_viewer
}

fragment Logout_viewer on Admin {
  id
  username
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_App_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "App_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_App_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 99
              }
            ],
            "concreteType": "AdminRoleConnection",
            "kind": "LinkedField",
            "name": "roles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminRoleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "roles(first:99)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2220cc6cd462801f6e0bab00473a885c",
    "id": null,
    "metadata": {},
    "name": "routes_App_Query",
    "operationKind": "query",
    "text": "query routes_App_Query {\n  viewer {\n    ...App_viewer\n    id\n  }\n}\n\nfragment App_viewer on Admin {\n  id\n  username\n  roles(first: 99) {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n  ...Logout_viewer\n}\n\nfragment Logout_viewer on Admin {\n  id\n  username\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0cbf88fe703c3b585c8f6215d6850996';

module.exports = node;
