import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Cookies from 'universal-cookie';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { formItemLayout } from '~/components/form';

import { LoginMutation } from './mutations';
import { useCapture } from './Captcha';

const { Item: FormItem } = Form;

const cookies = new Cookies();

const getMaxAge = function getMaxAge(days) {
  const day = 24 * 60 * 60;
  return days * day;
};

const Login = (props) => {
  const { viewer, relay } = props;

  const [form] = Form.useForm();
  const [Capture, failLoginHandler] = useCapture(form);

  const handleSubmit = (values) => {
    LoginMutation.commit({
      environment: relay.environment,
      variables: { input: values },
      viewer,
      onCompleted: (resp, errors) => {
        if (!errors) {
          cookies.set('auth_token', resp.login.token, { path: '/', maxAge: getMaxAge(60) });
          window.location.reload();
        }
      },
      onError: (errors) => {
        if (errors[0] && errors[0].message) {
          const msg = errors[0].message;
          message.error(msg);
          failLoginHandler(msg);
        }
      },
    });
  }

  return !viewer ? (
    <Row>
      <Col xs={12} >
        <h1>Log In</h1>
        <Form form={form} onFinish={handleSubmit}>
          <FormItem
            {...formItemLayout}
            label="Username"
            name="username"
            rules={[
              { required: true, message: 'Required' },
            ]}
            extra="DO NOT USE EMAIL"
          >
            <Input
              autoComplete="username"
              prefix={<UserOutlined />}
              placeholder="Username"
            />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Password"
            name="password"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input
              autoComplete="current-password"
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </FormItem>

          <FormItem
            {...formItemLayout}
          >
            <Button
              type="primary"
              htmlType="submit"
            >
              Log in
            </Button>
          </FormItem>

          <Capture />

        </Form>
      </Col>
    </Row>
  ) : null;
};

Login.propTypes = {
  viewer: PropTypes.shape({
    username: PropTypes.string,
  }),
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
};

Login.defaultProps = {
  viewer: null,
};

export default createFragmentContainer(Login, {
  viewer: graphql`
    fragment Login_viewer on Admin {
      username
    }
  `,
});
