/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddStateManagerInput = {|
  emails: $ReadOnlyArray<?string>,
  state: string,
|};
export type AddStateManagerMutationVariables = {|
  input: AddStateManagerInput
|};
export type AddStateManagerMutationResponse = {|
  +addStateManager: ?{|
    +stateManagerEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +emails: ?$ReadOnlyArray<?string>,
        +state: ?string,
      |},
    |}
  |}
|};
export type AddStateManagerMutation = {|
  variables: AddStateManagerMutationVariables,
  response: AddStateManagerMutationResponse,
|};
*/


/*
mutation AddStateManagerMutation(
  $input: AddStateManagerInput!
) {
  addStateManager(input: $input) {
    stateManagerEdge {
      cursor
      node {
        id
        emails
        state
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddStateManagerPayload",
    "kind": "LinkedField",
    "name": "addStateManager",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StateManagerEdge",
        "kind": "LinkedField",
        "name": "stateManagerEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StateManager",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emails",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddStateManagerMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddStateManagerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "50209a7ed726655d1d16e0df177f845d",
    "id": null,
    "metadata": {},
    "name": "AddStateManagerMutation",
    "operationKind": "mutation",
    "text": "mutation AddStateManagerMutation(\n  $input: AddStateManagerInput!\n) {\n  addStateManager(input: $input) {\n    stateManagerEdge {\n      cursor\n      node {\n        id\n        emails\n        state\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '28bffbd99d924ba798556265be155bab';

module.exports = node;
