/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StateManagerNew_viewer$ref = any;
export type routes_StateManagerNew_QueryVariables = {||};
export type routes_StateManagerNew_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: StateManagerNew_viewer$ref
  |}
|};
export type routes_StateManagerNew_Query = {|
  variables: routes_StateManagerNew_QueryVariables,
  response: routes_StateManagerNew_QueryResponse,
|};
*/


/*
query routes_StateManagerNew_Query {
  viewer {
    ...StateManagerNew_viewer
    id
  }
}

fragment StateManagerNew_viewer on Admin {
  id
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_StateManagerNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StateManagerNew_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_StateManagerNew_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c2e0c8c171e4c5d5dbb4614f0b0096ea",
    "id": null,
    "metadata": {},
    "name": "routes_StateManagerNew_Query",
    "operationKind": "query",
    "text": "query routes_StateManagerNew_Query {\n  viewer {\n    ...StateManagerNew_viewer\n    id\n  }\n}\n\nfragment StateManagerNew_viewer on Admin {\n  id\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = '44a2990a4a4046a3400731eedaa31eae';

module.exports = node;
