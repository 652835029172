import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { Button, Select } from 'antd';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

import DropdownFilter from './DropdownFilter';
import DateFilter from './DateFilter';
import { getDataSource, PAGE_SIZE, setPageSize } from './getDataSource';

const { Option } = Select;

export default class Grid extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
    }),
    columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
    onGridReady: PropTypes.func,
    datasource: PropTypes.shape({
      rowCount: PropTypes.number.isRequired,
      getRows: PropTypes.func.isRequired,
    }).isRequired,
    bulkActions: PropTypes.arrayOf(PropTypes.object)
  }

  static defaultProps = {
    viewer: {},
    bulkActions: [],
    onGridReady: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      activeAction: null,
      gridApi: null,
    };
  }

  onGridReady(params) {
    this.setState({
      gridApi: params.api,
    });

    if (this.props.onGridReady) {
      this.props.onGridReady(params);
    }
  }

  static getPageSize =  function localPageSize() {
    return PAGE_SIZE();
  }

  static getDataSource = getDataSource

  refresh = () => {
    if (this.state.gridApi) {
      this.state.gridApi.refreshInfiniteCache();
    }
    else {
      console.error('Grid API is null');
    }
  }

  renderBulkActions() {
    if (this.state.gridApi && this.props.bulkActions.length > 0) {
      const actions = this.props.bulkActions;
      return (
        <div>
          <Select
            placeholder="Bulk Action"
            style={{ width: '200px' }}
            onChange={(value, option) => {
              this.setState({ activeAction: option.props.action });
            }}
          >
            {
              actions.map(action => <Option key={action.title} action={action}>{action.title}</Option>)
            }
          </Select>

          {get(this.state.activeAction, 'node') &&
            React.cloneElement(this.state.activeAction.node, { viewer: this.props.viewer, gridApi: this.state.gridApi })
          }

        </div>
      );
    }
    return null;
  }

  render() {
    const { columnDefs, datasource } = this.props;
    return (
      <div className="ag-theme-balham">
        {this.renderBulkActions()}

        <Button onClick={()=> this.refresh()}>
          Refresh List
        </Button>
        <Select
          style={{ width: '150px' }}
          defaultValue={Grid.getPageSize()}
          onChange={(value) => {
            setPageSize(value);
            window.location.reload();
          }}
        >
          <Option value={100}>Page Size: 100</Option>
          <Option value={500}>Page Size: 500</Option>
          <Option value={1000}>Page Size: 1000</Option>
        </Select>

        <AgGridReact
          animateRows
          cacheBlockSize={Grid.getPageSize()}
          cacheOverflowSize={0}
          columnDefs={columnDefs}
          datasource={datasource}
          defaultColDef={{
            width: 100,
            sortable: true,
            resizable: true,
            filter: true,
          }}
          domLayout="autoHeight"
          floatingFilter
          frameworkComponents={{ DropdownFilter, DateFilter }}
          onGridReady={(params) => { this.onGridReady(params) }}
          pagination
          paginationPageSize={Grid.getPageSize()}
          reactNext
          rowClassRules={{
            'ag-row-entity-disabled': (params) => {
              if (params.data) {
                const status = ['disabled', 'end-of-life', false].includes(params.data.node.status);
                const deletedAt = get(params, 'data.node.deletedAt');

                return status || deletedAt;
              }
              return null;
            },
          }}
          rowHeight={100}
          rowModelType="infinite"
          rowSelection="multiple"
          sortingOrder={['desc', 'asc']}
          suppressRowClickSelection
        />
      </div>
    );
  }
}
