import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';

import moment from 'moment-timezone';
import { Affix, Button, Divider, Form, Input, InputNumber, message, Popconfirm, Select, Space, Switch, Tabs } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { DatePicker, Editor, FormBase, formItemLayout, SelectProduct } from '~/components/form';
import Presence from '~/components/Presence';
import { UrlRefresher } from '~/components/url';
import { ShowLabel } from '~/components/promotion/label';
import FeaturedBrand from './FeaturedBrand';
import { getFrontEndUrl } from '../../helper';
import CategoryHistory from './CategoryHistory';
import { CopyToNzBtn, Loading } from '../product/WebsiteRef';

const { Item: FormItem } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { TabPane } = Tabs;

class CategoryForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    entity: PropTypes.shape({
      id: PropTypes.string
    }),
    onSubmit: PropTypes.func.isRequired,
    remove: PropTypes.func,
  }

  static defaultProps = {
    entity: {},
    match: {},
    remove: null,
  }

  constructor(props) {
    super(props);

    const { entity: category } = props;

    this.formRef = React.createRef();

    this.state = {
      downloadUrl: `/api/promotion/download?id=${category.id}`,
      previewDate: null,
    }
  }

  onDateChange = (category, datetime) => {
    this.setState({
      downloadUrl: `/api/promotion/download?id=${category.id}&datetime=${datetime.toISOString()}`,
    });
  }

  setPreviewDate = (val) => {
    const previewDate = val.format('YYYY-MM-DD');
    this.setState({
      previewDate
    });
  }

  goPreviewDate = (e, category) => {
    const { previewDate } = this.state;
    const url = get(category, 'urlSlug');

    if (previewDate && url) {
      window.open(`${getFrontEndUrl()}/category/${url}?preview=${previewDate}`, '_blank');
    } else {
      message.error("Please enter a preview date.")
    }
  }

  disabledDate = current => current && current < moment().startOf('day')

  scheduleValues = (k) => ({
    datetime: get(k, 'datetime') ? moment(k.datetime) : null,
    products: get(k, 'products.edges', []).map(({ node }) => ({
      key: node.id,
      label: (
        <div>
          <img width="20" height="20" src={get(node, 'mainImage.url', '')} alt="" />
          {get(node, 'name', '')}
        </div>
      ),
    }))
  })

  render() {
    const { entity: category, match, viewer } = this.props;
    const assignment = get(category, 'scheduleAssignments', []);
    const removal = get(category, 'scheduleRemovals', []);

    const defaultScheduleValue = { datetime: null, products: [] };

    const initialValue = {
      scheduleAssignments: assignment.length ? assignment.slice(0).map(this.scheduleValues) : [defaultScheduleValue],
      scheduleRemovals: removal.length ? removal.slice(0).map(this.scheduleValues) : [defaultScheduleValue],
    };

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <br />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()} style={{ marginRight: '10px' }}>Save</Button>
            {category.id && (
              <React.Suspense fallback={<Loading />}>
                <CopyToNzBtn viewer={viewer} entity={category} />
              </React.Suspense>
            )}
            {category.id && (
              <span>
                <Popconfirm
                  title="Are you sure to delete this category?"
                  onConfirm={() => { this.props.remove(category); }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button disabled={this.shouldDisableBtn()}>Delete</Button>
                </Popconfirm>
                <br />
                <br />
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  onChange={(datetime) => this.onDateChange(category, datetime)}
                />
                <a href={this.state.downloadUrl} target="_blank" rel="noopener noreferrer">Download</a>
              </span>
            )}
          </div>
        </Affix>

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <FormItem
              name="id"
              initialValue={category.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Name"
              required
            >
              <FormItem
                name="name"
                initialValue={category.name}
                rules={[{ required: true, message: 'required' }]}
              >
                <Input
                  placeholder="Name"
                  onChange={(e) => {
                    if (!category.id) {
                      const urlSlug = e.target.value;
                      this.formRef.current.setFieldsValue({ urlSlug });
                    }
                  }}
                />
              </FormItem>

              <ShowLabel labels={category.labels} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="URL"
            >
              <div>
                <Input placeholder="URL" value={get(category, 'urlSlug')} readOnly />
                <UrlRefresher entity={category} viewer={viewer} />
              </div>
            </FormItem>

            {category.id && (
              <FormItem
                {...formItemLayout}
                label="Preview Category"
              >
                <div>
                  <DatePicker placeholder="Preview Date" onChange={this.setPreviewDate} />
                  <Button
                    type="link"
                    onClick={(e) => {
                      this.goPreviewDate(e, category);
                    }}
                  >
                    Go to Preview
                  </Button>
                </div>
              </FormItem>
            )}

            <FormItem
              {...formItemLayout}
              label="Include In Nav Menu"
              name='includeInMenu'
              valuePropName='checked'
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(category, 'includeInMenu', false)}
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Include In Search"
              name='includeInSearch'
              valuePropName='checked'
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(category, 'includeInSearch', false)}
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Include In Sitemap"
              name='includeInSitemap'
              valuePropName='checked'
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(category, 'includeInSitemap', true)}
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Include In Filter"
              name='includeInFilter'
              valuePropName='checked'
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(category, 'includeInFilter', false)}
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Internal Price Match"
              name='internalPriceMatch'
              valuePropName='checked'
              initialValue={get(category, 'internalPriceMatch', true)}
              extra={
                <span>
                  If disabled, products under this category will be prevented
                  from price matching initiated from <i><u>price.sydneytools.com.au</u></i>
                </span>
              }
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Featured"
              extra="Popular Category"
              name='featured'
              valuePropName='checked'
              initialValue={get(category, 'featured', false)}
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Wide Image"
              name='wideImage'
              valuePropName='checked'
              initialValue={category.wideImage}
            >
              <Switch />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Position"
              name='position'
              rules={[{ required: false, message: 'required' }]}
              initialValue={category.position || 0}
            >
              <InputNumber placeholder="Position" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Search Aliases"
              name='aliases'
              initialValue={category.aliases || []}
            >
              <Select placeholder="Aliases" mode="tags">
                {
                  (category.aliases || []).map(name => <Option key={name} value={name}>{name}</Option>)
                }
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Display Mode"
              name='displayMode'
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(category, 'displayMode', 'subcategory')}
            >
              <Select placeholder="Status">
                <Option value="subcategory">Subcategory</Option>
                <Option value="product">Product</Option>
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Meta Title"
              name='metaTitle'
              initialValue={category.metaTitle}
            >
              <TextArea rows={2} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Meta Description"
              name='metaDescription'
              initialValue={category.metaDescription}
            >
              <TextArea rows={3} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Schmea JSON LD"
              name='jsonLd'
              initialValue={category.jsonLd}
            >
              <TextArea rows={3} />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              name='status'
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(category, 'status') ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status Enable From To"
              name='enableFromTo'
              rules={[{ required: false, message: 'required' }]}
              initialValue={[
                category.enableStartDate ? moment(category.enableStartDate) : null,
                category.enableEndDate ? moment(category.enableEndDate) : null
              ]}
            >
              <RangePicker
                disabledDate={this.disabledDate}
                format="YYYY-MM-DD"
              />
            </FormItem>

            <Divider />

            <h2>Description</h2>
            <Editor
              name="description"
              editorState={category.description}
            />

            <h2>Description 2</h2>
            <Editor
              name="description2"
              editorState={category.description2}
            />
          </TabPane>

          <TabPane tab="Preview Description" key="preview_description" forceRender>
            <Editor
              name="previewDescription"
              editorState={category.previewDescription}
            />
          </TabPane>

          {category.id && (
            <TabPane tab="Featured Brands" key="featured_brands" forceRender>
              <FeaturedBrand viewer={viewer} category={category} />
            </TabPane>
          )}

          <TabPane tab="Schedule" key="scheudle" forceRender>
            <Form.List
              name="scheduleAssignments"
              initialValue={initialValue.scheduleAssignments}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <div key={key}>
                      <FormItem
                        {...formItemLayout}
                        label="Assignment Date"
                        extra="Time is at 00:00:00"
                      >
                        <Space align='baseline'>
                          <FormItem name={[name, 'datetime']}>
                            <DatePicker placeholder="Date" />
                          </FormItem>

                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              style={{ marginLeft: '10px' }}
                              onClick={() => remove(name)}
                            />
                          ) : null}
                        </Space>
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="Products"
                        name={[name, 'products']}
                      >
                        <SelectProduct
                          placeholder="Products"
                          mode="multiple"
                          labelInValue
                          filterOption={false}
                          viewer={viewer}
                        />
                      </FormItem>
                    </div>
                  ))}

                  <Button
                    onClick={() => add(defaultScheduleValue)}
                    style={{ marginLeft: '5px' }}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </>
              )}
            </Form.List>

            <Divider />

            <Form.List
              name="scheduleRemovals"
              initialValue={initialValue.scheduleRemovals}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <div key={key}>
                      <FormItem
                        {...formItemLayout}
                        label="Removal Date"
                        extra="Time is at 00:00:00"
                      >
                        <Space align='baseline'>
                          <FormItem name={[name, 'datetime']}>
                            <DatePicker placeholder="Date" />
                          </FormItem>

                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              style={{ marginLeft: '10px' }}
                              onClick={() => remove(name)}
                            />
                          ) : null}
                        </Space>
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label="Products"
                        name={[name, 'products']}
                      >
                        <SelectProduct
                          placeholder="Products"
                          mode="multiple"
                          labelInValue
                          filterOption={false}
                          viewer={viewer}
                        />
                      </FormItem>
                    </div>
                  ))}

                  <Button
                    onClick={() => add(defaultScheduleValue)}
                    style={{ marginLeft: '5px' }}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </>
              )}
            </Form.List>
          </TabPane>

          {category.id && (
            <TabPane tab="History" key="history">
              <CategoryHistory viewer={viewer} category={category} />
            </TabPane>
          )}
        </Tabs>
      </Form>
    );
  }
}
export default createFragmentContainer(CategoryForm, {
  viewer: graphql`
    fragment CategoryForm_viewer on Admin {
      id
      ...CategoryHistory_viewer
      ...SelectProduct_viewer
      ...UrlRefresher_viewer
      ...FeaturedBrand_viewer
    }
  `,
});
