import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation AddStateManagerMutation($input: AddStateManagerInput!) {
    addStateManager(input:$input) {
      stateManagerEdge {
        cursor
        node {
          id
          emails
          state
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
