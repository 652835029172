import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { DatePicker as AntdDatePicker } from 'antd';

const { RangePicker: AntdRangePicker } = AntdDatePicker;

// https://github.com/ant-design/ant-design/issues/47490
const DatePicker = (props) => {
  const { defaultValue, value, onChange, ...rest } = props;

  const [inputValue, setInputValue] = useState(defaultValue || value);

  const handleOnChange = (date, dateString) => {
    const ensureDate = date ? moment(dateString) : date;

    setInputValue(ensureDate);
    onChange(ensureDate, dateString);
  };

  return (
    <AntdDatePicker value={inputValue} onChange={handleOnChange} {...rest} />
  );
};

DatePicker.propTypes = {
  defaultValue: PropTypes.shape({}),
  value: PropTypes.shape({}),
  onChange: PropTypes.func,
};

DatePicker.defaultProps = {
  defaultValue: null,
  value: null,
  onChange: () => { },
};

const RangePicker = (props) => {
  const { defaultValue, value, onChange, ...rest } = props;
  const [inputValue, setInputValue] = useState(defaultValue || value);

  const handleOnChange = (dates, dateStrings) => {
    const ensureDates = dates ? [moment(dateStrings[0]), moment(dateStrings[1])] : dates;

    setInputValue(ensureDates);
    onChange(ensureDates, dateStrings);
  };

  return (
    <AntdRangePicker value={inputValue} onChange={handleOnChange} {...rest} />
  )
};

RangePicker.propTypes = {
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

RangePicker.defaultProps = {
  defaultValue: null,
  value: null,
  onChange: () => { },
};

DatePicker.MonthPicker = AntdDatePicker.MonthPicker;
DatePicker.QuarterPicker = AntdDatePicker.QuarterPicker;
DatePicker.RangePicker = RangePicker;
DatePicker.TimePicker = AntdDatePicker.TimePicker;
DatePicker.WeekPicker = AntdDatePicker.WeekPicker;
DatePicker.YearPicker = AntdDatePicker.YearPicker;

export default DatePicker;
